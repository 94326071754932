import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';

import { getMyPets } from '../../services/user.service';
import { PEET_NFT_SERVER } from '../../constants';
import { getTimestamptString } from '../../utils';

export default function MyPets() {
  const [isLoading, setisLoading] = useState<boolean>(false);
  const [isMountEnabled, setisMountEnabled] = useState(true);
  const [items, setitems] = useState([]);

  useEffect(() => {
    const getItems = async () => {
      setisLoading(true);
      const serviceRes = await getMyPets();

      if (serviceRes.isSucceeded) {
        setitems(serviceRes.items);
      } else {
        console.error('[Error] No profile found.');
      }
      setisLoading(false);
    };

    if (isMountEnabled) {
      getItems();
    }
    return () => {
      setisMountEnabled(false);
      // axiosCancelSource.cancel();
    };
  }, [isMountEnabled]);

  return (
    <Card sx={{ marginBottom: '20px' }}>
      <CardHeader title="My Peets" />

      {isLoading === true && (
        <Grid
          container
          direction={'row'}
          justifyContent={'flex-start'}
          style={{ padding: '16px', height: '280px' }}
        >
          <Grid item xs={12} sm={12} md={4} style={{ padding: '0px 8px' }}>
            <Skeleton height={'100%'} />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={4}
            container
            direction={'column'}
            justifyContent={'center'}
            alignItems={'flex-start'}
            style={{ padding: '0px 8px' }}
          >
            <Skeleton width={'100%'} height={'30px'} />
            <Skeleton width={'100%'} height={'30px'} />
            <Skeleton width={'60%'} height={'30px'} />
          </Grid>
        </Grid>
      )}

      {isLoading === false && items.length === 0 && (
        <Typography
          component={'p'}
          variant="h5"
          align="center"
          style={{ padding: '0px', margin: '48px 0px' }}
        >
          No donation data
        </Typography>
      )}

      {isLoading === false &&
        items.length !== 0 &&
        items.map((item: any, index: number) => {
          return (
            <CardContent key={`card-content-${index}`} style={{}}>
              <Card id={`card-${index}`} sx={{ display: 'flex' }}>
                <Grid container>
                  <Grid item xs={12} sm={12} md={4}>
                    <LazyLoadImage
                      alt={''}
                      height={'200px'}
                      src={`${PEET_NFT_SERVER}/characters/view/${item.id}.svg`}
                      style={{
                        backgroundColor: `${item.parts[1].color}30`,
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column'
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4}>
                    <CardContent sx={{ display: 'flex', flexDirection: 'column' }}>
                      <Typography gutterBottom variant="h5" component="div">
                        {item.name}
                      </Typography>
                      <Typography
                        gutterBottom
                        variant="subtitle1"
                        style={{ textTransform: 'capitalize', marginBottom: '20px' }}
                      >
                        {item.masterChar.name.replace(/[_-]/g, ' ')}
                      </Typography>

                      <Typography
                        gutterBottom
                        component="p"
                        variant="body2"
                        color="text.secondary"
                      >
                        {getTimestamptString(item.adopted.createdAt, 'DD/MM/YYYY hh:mmA')}
                      </Typography>
                      {item.adopted.length > 0 && (
                        <Button
                          href={item.adopted[0].opensea_link}
                          target={'_blank'}
                          size="small"
                          variant="contained"
                          color="info"
                          style={{ marginTop: '20px' }}
                        >
                          View on OpenSea
                        </Button>
                      )}
                    </CardContent>
                  </Grid>
                </Grid>
              </Card>
            </CardContent>
          );
        })}
    </Card>
  );
}
