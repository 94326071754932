import React, { useState } from 'react';
import {
  // useLocation,
  useNavigate
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@mui/material/Grid';

import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

import { login } from '../../services';
import { setAuthSuccess, authSelector } from '../../features/auth.slice';

export default function EmailLogin() {
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const { peetId, currentUrl } = useSelector(authSelector);

  const [isLoading, setisLoading] = useState<boolean>(false);
  const [formData, setformData] = useState<any>({
    email: '',
    password: ''
  });
  const [formErrors, setformErrors] = useState<any>({});
  const [formResult, setformResult] = useState<any>({});

  const handleInputOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputName = event.target.name;
    const inputValue = event.target.value;

    setformData({ ...formData, [inputName]: inputValue });

    if (inputValue === '') {
      setformErrors({ ...formErrors, [inputName]: true });
      return;
    }
    setformErrors({ ...formErrors, [inputName]: false });
    setformResult({});
  };

  const handleLogIn = async () => {
    try {
      setisLoading(true);

      let isFormReady = true;

      let stateFormErrors = formErrors;
      for (const prop in formData) {
        if (formData[prop] === '') {
          isFormReady = false;
          stateFormErrors = { ...stateFormErrors, [prop]: true };
        }
      }
      setformErrors(stateFormErrors);

      if (!isFormReady) return;

      const loginPayload = formData;
      if (peetId) loginPayload.characterId = peetId;
      if (currentUrl) loginPayload.currentUrl = currentUrl;

      const serviceRes: any = await login(loginPayload);

      if (serviceRes.isSucceeded) {
        dispatch(setAuthSuccess(serviceRes.item));

        if (!peetId) {
          navigate('/profile');
        }
        if (peetId) {
          window.open(serviceRes.item.redirect_link, '_self', 'noreferrer');
        }
      } else {
        setformResult({ isFormFailed: true, message: serviceRes.message });
      }
    } catch (error) {
      console.error('[Error] in login form submit', error);
    } finally {
      setisLoading(false);
    }
  };

  return (
    <>
      {formResult.message !== '' && (
        <Typography
          paragraph
          align="center"
          style={{ padding: '8px 12px', color: 'red' }}
        >
          {formResult.message}
        </Typography>
      )}
      <form onSubmit={(e) => e.preventDefault()}>
        <Grid
          id="verify-grid-container"
          container
          style={{
            margin: '0px',
            padding: '8px 12px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            alignItems: 'flex-end'
          }}
        >
          <Grid item xs={12}>
            <TextField
              variant="standard"
              label="Email"
              id="email"
              name="email"
              fullWidth
              placeholder="Enter Email"
              // type={'number'}
              error={formErrors?.email}
              helperText={formErrors?.email ? 'Enter Email' : ''}
              onChange={handleInputOnChange}
              style={{
                padding: '0px',
                margin: '8px 4px 0px 0px',
                flexGrow: '1',
                borderRadius: 0
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="standard"
              label="Password"
              id="password"
              name="password"
              fullWidth
              placeholder="Enter Password"
              type={'password'}
              error={formErrors?.password}
              helperText={formErrors?.password ? 'Enter Password' : ''}
              onChange={handleInputOnChange}
              style={{
                padding: '0px',
                margin: '8px 4px 0px 0px',
                flexGrow: '1',
                borderRadius: 0
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={5}>
            <Button
              fullWidth
              variant="contained"
              type="submit"
              onClick={handleLogIn}
              disabled={isLoading}
              style={{
                margin: '24px 0px 0px 0px',
                fontSize: '16px'
              }}
            >
              Login
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  );
}
