import axios from 'axios';

import { PEET_NFT_SERVER } from '../constants';
import { IResponse } from '../interfaces';

export const getProfile = async (): Promise<any> => {
  const resData: IResponse = {
    isSucceeded: false,
    message: '',
    error: {},
    item: {}
  };

  try {
    const token = localStorage.getItem('token') || '';
    const res = await axios.get(`${PEET_NFT_SERVER}/user/profile`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    });

    const userRes = res.data.user;
    resData.isSucceeded = true;
    resData.item = userRes;

    return resData;
  } catch (error: any) {
    console.error('[Error]: getProfile', error);
    resData.message = error.message;
    resData.error = error;
    return resData;
  }
};

export const getMyPets = async (): Promise<any> => {
  const resData: IResponse = {
    isSucceeded: false,
    message: '',
    error: {},
    items: [],
    itemCount: 0
  };

  try {
    const token = localStorage.getItem('token') || '';
    const response = await axios.get(`${PEET_NFT_SERVER}/user/peet`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    });
    resData.isSucceeded = true;
    resData.items = response.data;

    return resData;
  } catch (error: any) {
    console.error('[Error]: getMyPets', error);
    resData.message = error.message;
    resData.error = error;
    return resData;
  }
};

export const getDonationHistory = async (): Promise<any> => {
  const resData: IResponse = {
    isSucceeded: false,
    message: '',
    error: {},
    items: []
  };

  try {
    const token = localStorage.getItem('token') || '';
    const res = await axios.get(`${PEET_NFT_SERVER}/user/donations`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    });

    const userRes = res.data;
    resData.isSucceeded = true;
    resData.items = userRes;

    return resData;
  } catch (error: any) {
    console.error('[Error]: getDonationHistory', error);
    resData.message = error.message;
    resData.error = error;
    return resData;
  }
};
