import moment from 'moment-timezone';

export function getTimestamptString(date: string | Date, dateFormat?: string): string {
  try {
    if (!date) return '';

    const timestampt = moment.tz(date, 'Asia/Singapore');

    if (!dateFormat) return timestampt.format();

    return timestampt.format(dateFormat);
  } catch (error) {
    console.error('[Error] getTimestamptString');
    return '';
  }
}
