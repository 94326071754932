import React, { useEffect, useState } from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import TextField from '@mui/material/TextField';
import Skeleton from '@mui/material/Skeleton';

import { getProfile } from '../../services/user.service';

export default function ContactInfo() {
  const [isLoading, setisLoading] = useState<boolean>(false);
  const [isMountEnabled, setisMountEnabled] = useState(true);
  const [details, setdetails] = useState<any>({});

  useEffect(() => {
    const getItems = async () => {
      setisLoading(true);
      const serviceRes = await getProfile();

      if (serviceRes.isSucceeded) {
        setdetails(serviceRes.item);
      } else {
        console.error('[Error] No profile found.');
      }
      setisLoading(false);
    };

    if (isMountEnabled) {
      getItems();
    }
    return () => {
      setisMountEnabled(false);
      // axiosCancelSource.cancel();
    };
  }, [isMountEnabled]);

  return (
    <Card sx={{ marginBottom: '20px' }}>
      <CardHeader title="Profile Details" />
      <CardContent>
        {isLoading === true && (
          <>
            <Skeleton height={'50px'} />
            <Skeleton width={'60%'} height={'50px'} />
            <Skeleton width={'60%'} height={'50px'} />
          </>
        )}
        {isLoading === false && (
          <>
            <TextField
              id="outlined-helperText"
              label="Name"
              fullWidth
              value={details.name || ''}
              size="small"
              margin="normal"
              InputProps={{
                readOnly: true
              }}
            />
            <TextField
              id="outlined-helperText"
              label="Phone"
              fullWidth
              value={details.mobile || ''}
              size="small"
              margin="normal"
              InputProps={{
                readOnly: true
              }}
            />
            <TextField
              id="outlined-helperText"
              label="Email"
              fullWidth
              value={details.email || ''}
              size="small"
              margin="normal"
              InputProps={{
                readOnly: true
              }}
            />
          </>
        )}
      </CardContent>
    </Card>
  );
}
