import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import axios from 'axios';
import _ from 'lodash';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useSelector, useDispatch } from 'react-redux';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import SendIcon from '@mui/icons-material/Send';

import { PEET_NFT_SERVER } from '../../constants';
import { HundredStraysWrapper, hundredStraysStyles } from './HundredStrays.style';
import { getRandomCharacters, adoptCharacter } from '../../services';
import logo from '../../images/chatrity-logo.svg';
import heartIcon from '../../images/heart.png';
import heroBannerMain from '../../images/hero-banner-main.png';
import CustomMeta from '../../components/CustomMeta';
import {
  authSelector,
  // setPeetId,
  // setCurrentUrl,
  setLoading
} from '../../features/auth.slice';
// import { setOpenLoginDialog } from '../../features/dialogBox.slice';

import opensea from '../../images/opensea.svg';
import metamask from '../../images/metamask.svg';
import polygon from '../../images/polygon.svg';

export default function HundredStrays() {
  const classes = hundredStraysStyles();
  const dispatch = useDispatch();

  const {
    // isAuth,
    isLoading
  } = useSelector(authSelector);

  const [isMountEnabled, setisMountEnabled] = useState(true);
  const [items, setitems] = useState([]);

  const adoptRandomClick = async (id: number) => {
    try {
      // if (!isAuth) {
      //   dispatch(setCurrentUrl(window.location.href));
      //   dispatch(setPeetId(id));
      //   dispatch(setOpenLoginDialog({ title: '', message: '', type: 'LOGIN' }));
      //   return;
      // }

      const newItemList = [...items];
      newItemList.forEach((i: any) => {
        if (i.id === id) {
          i.isClicked = true;
        }
      });
      setitems(newItemList);

      const adoptPayload = {
        characterId: id
      };
      const itemsResponse = await adoptCharacter(adoptPayload);
      const detailsData = itemsResponse.item;

      if (_.isEmpty(detailsData) || !detailsData.url) {
        console.error('[Error] no adopt data or token');
        return;
      }

      window.open(detailsData.url, '_self', 'noreferrer');
    } catch (error) {
      console.error('[Error] in adoptRandomClick', error);
    } finally {
      const newItemList = [...items];
      newItemList.forEach((i: any) => {
        if (i.id === id) {
          i.isClicked = false;
        }
      });
      setitems(newItemList);
    }
  };

  useEffect(() => {
    const axiosCancelSource = axios.CancelToken.source();
    const axiosCancelToken = axiosCancelSource.token;

    const getItems = async () => {
      dispatch(setLoading(true));
      const itemsResponse = await getRandomCharacters(axiosCancelToken, {
        status: 'available',
        itemsCount: 5
      });
      if (itemsResponse.items.length) {
        setitems(itemsResponse.items);
      } else {
        console.log('[Info] No HundredStrays item');
      }
      dispatch(setLoading(false));
    };

    if (isMountEnabled) {
      getItems();
    }

    return () => {
      setisMountEnabled(false);
      axiosCancelSource.cancel();
    };
  }, [isMountEnabled, dispatch]);

  return (
    <HundredStraysWrapper id="hundred-peet-wrapper">
      <CustomMeta
        title={`Adopt a digital stray with a SGD 30 donation`}
        imageUrl={`https://peet.me${heroBannerMain}`}
        description={`Donate to animal welfare and Collect an Exclusive NFT!`}
      />
      <Grid container marginBottom={'48px'}>
        <Grid
          item
          md={12}
          lg={7}
          container
          justifyContent={'flex-start'}
          alignItems={'center'}
        >
          <Typography
            component="h1"
            variant="h2"
            color="#FFF"
            gutterBottom
            style={{ fontSize: '3em' }}
          >
            Donate to animal welfare and Collect an Exclusive NFT!
          </Typography>
          <Typography variant="h6" color="#FFF" paragraph>
            Adopt a digital stray with a SGD 30 donation
          </Typography>
        </Grid>

        <Grid
          item
          container
          justifyContent={'center'}
          alignItems={'center'}
          xs={12}
          sm={12}
          md={12}
          lg={5}
        >
          <Grid
            item
            sm={12}
            md={7}
            lg={11}
            container
            justifyContent={'center'}
            alignItems={'center'}
          >
            <LazyLoadImage
              alt={''}
              src={heroBannerMain}
              style={{
                width: '100%',
                maxWidth: '340px'
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        justifyContent={'center'}
        alignItems={'center'}
        marginBottom={'48px'}
      >
        <Grid item flexGrow={1} md={12} lg={8}>
          {isLoading === false &&
            items.length !== 0 &&
            items.slice(0, 1).map((item: any, index: number) => {
              const btnClass = item.isClicked ? 'dark-adopt-button-disabled' : '';
              return (
                <LoadingButton
                  disabled
                  fullWidth
                  id={`hero_adopt_link_${item.id}`}
                  className={`hero_adopt_link_${item.id} ${btnClass} dark-adopt-button-disabled`}
                  key={`hero-button-${index}`}
                  size="large"
                  variant="contained"
                  style={{ fontSize: '28px' }}
                  loadingPosition="start"
                  loading={item.isClicked}
                  startIcon={<></>}
                  onClick={() => {
                    adoptRandomClick(item.id);
                  }}
                >
                  {/* Click here to Donate Now */}
                  This campaign has ended.
                </LoadingButton>
              );
            })}
        </Grid>
      </Grid>

      <Grid container spacing={'8px'} marginBottom={'50px'}>
        <Grid item xs={6} md={6} lg={3} style={{ textAlign: 'center', padding: '12px' }}>
          <img
            className={'hero-logo'}
            src={logo}
            style={{ opacity: '0.5', width: '100%', maxWidth: '160px' }}
            alt=""
          />
        </Grid>
        <Grid item xs={6} md={6} lg={3} style={{ textAlign: 'center', padding: '12px' }}>
          <img
            className={'hero-logo'}
            src={opensea}
            style={{ opacity: '0.5', width: '100%', maxWidth: '160px' }}
            alt=""
          />
        </Grid>
        <Grid item xs={6} md={6} lg={3} style={{ textAlign: 'center', padding: '12px' }}>
          <img
            className={'hero-logo'}
            src={metamask}
            style={{ opacity: '0.5', width: '100%', maxWidth: '160px' }}
            alt=""
          />
        </Grid>
        <Grid item xs={6} md={6} lg={3} style={{ textAlign: 'center', padding: '12px' }}>
          <img
            className={'hero-logo'}
            src={polygon}
            style={{ opacity: '0.5', width: '100%', maxWidth: '160px' }}
            alt=""
          />
        </Grid>
      </Grid>

      <Grid
        container
        direction={'row'}
        justifyContent={'space-evenly'}
        // spacing="24px"
        marginBottom={'50px'}
      >
        <Grid item sm={12} md={8} lg={9} style={{ padding: '12px' }}>
          <Card
            style={{
              display: 'block',
              backgroundColor: '#1f0422',
              height: '100%'
            }}
          >
            <CardContent style={{ padding: '20px 40px', height: '100%' }}>
              <Typography
                variant="h6"
                style={{
                  color: '#FFF',
                  textAlign: 'left',
                  padding: '0px',
                  margin: '18px 12px 16px 12px',
                  fontWeight: 'bold'
                }}
              >
                PEETs <small>(Personality Embedded Evolving Tokens)</small> are a group of
                digital strays in Singapore looking for a socially conscious community to
                adopt, care and advocate for them.
              </Typography>
              <Typography
                style={{
                  color: '#FFF',
                  textAlign: 'left',
                  padding: '0px',
                  margin: '8px 12px 8px 12px'
                }}
              >
                Each PEET is unique in looks and personality and you can interact with
                your chosen PEET and see it grow and evolve on your social messaging apps
              </Typography>
              <Typography
                style={{
                  color: '#FFF',
                  textAlign: 'left',
                  padding: '0px',
                  margin: '8px 12px 8px 12px'
                }}
              >
                100% of proceeds will go to Chatrity fundraising platform’s partner animal
                charities - SPCA, SOSD and ACRES to fund emergency rescues,
                rehabilitation, medical expenses and more (for more information visit{' '}
                <a
                  href="https://hello.chatrity.com/charities"
                  target={'_blank'}
                  rel="noreferrer"
                  style={{ color: '#2196f3' }}
                >
                  https://hello.chatrity.com/charities
                </a>
                ).
              </Typography>
              <Button
                href="https://opensea.io/collection/peet-100strays"
                target={'_blank'}
                variant="contained"
                color="error"
                style={{ fontSize: '18px', marginTop: '16px' }}
                endIcon={<SendIcon />}
              >
                PEET 100 Strays on OpenSea
              </Button>
            </CardContent>
          </Card>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={4}
          lg={3}
          className={`${classes.item} card-wrapper`}
          style={{ padding: '12px' }}
        >
          {isLoading === true && (
            <Card
              key={`random-card-skeleton`}
              style={{ display: 'block', margin: '0px', padding: '0px 12px 12px 12px' }}
            >
              <Skeleton width={'100%'} height={'200px'} />
              <Skeleton />
              <Skeleton />
              <Skeleton width={'60%'} />
            </Card>
          )}
          {isLoading === false &&
            items.length !== 0 &&
            items.slice(0, 1).map((item: any, index: number) => {
              return (
                <Card
                  key={`random-card-${index}`}
                  className="hover-zoom"
                  style={{ display: 'block', margin: '0px auto' }}
                >
                  <RouterLink
                    to={`/character/${item.id}`}
                    style={{ textDecoration: 'none', color: '#000' }}
                  >
                    <div
                      className={classes.itemImage}
                      style={{ backgroundColor: `${item.parts[1].color}30` }}
                    >
                      <LazyLoadImage
                        alt={''}
                        height={'200px'}
                        src={`${PEET_NFT_SERVER}/characters/view/${item.id}.svg`}
                        style={{ display: 'block', margin: '0px auto' }}
                      />
                    </div>
                    <CardContent className="hover-bg-color">
                      <Typography
                        gutterBottom
                        variant="h5"
                        component="div"
                        style={{ textTransform: 'capitalize' }}
                      >
                        {item.name}
                      </Typography>
                      <Typography
                        component="h4"
                        variant="body2"
                        style={{ textTransform: 'capitalize', marginBottom: '20px' }}
                      >
                        {item.masterChar.name.replace(/[_-]/g, ' ')}
                      </Typography>
                    </CardContent>
                  </RouterLink>
                  <CardContent>
                    <LoadingButton
                      disabled
                      fullWidth
                      id={`special_adopt_link_${item.id}`}
                      className={`special_adopt_link_${item.id}`}
                      loadingPosition="start"
                      variant="contained"
                      startIcon={
                        <img
                          src={heartIcon}
                          id={`special_adopt_link_icon_${item.id}`}
                          className={`special_adopt_link_icon_${item.id}`}
                          alt=""
                        />
                      }
                      loading={item.isClicked}
                      onClick={() => {
                        adoptRandomClick(item.id);
                      }}
                    >
                      {/* Donate ${item.masterChar.minAmount} to Adopt */}
                      This campaign has ended.
                    </LoadingButton>
                  </CardContent>
                </Card>
              );
            })}
        </Grid>
      </Grid>

      <Typography component="h1" variant="h2" color="white" align="center" gutterBottom>
        PEET Gallery
      </Typography>
      {isLoading === false && items.length === 0 && (
        <Typography
          component={'p'}
          variant="h5"
          color="white"
          align="center"
          style={{ padding: '0px', margin: '48px 0px' }}
        >
          No PEET found
        </Typography>
      )}
      <Grid
        id="hundred-peet-container"
        container
        // spacing={'16px'}
      >
        {isLoading === true &&
          [1, 2, 3, 4].map((index: number) => {
            return (
              <Grid
                key={`skeleton-item-${index}`}
                className={classes.item}
                item
                xs={12}
                sm={12}
                md={6}
                lg={3}
                xl={3}
              >
                <Card
                  key={`random-card-skeleton`}
                  style={{
                    display: 'block',
                    margin: '0px',
                    padding: '0px 12px 12px 12px'
                  }}
                >
                  <Skeleton width={'100%'} height={'200px'} />
                  <Skeleton />
                  <Skeleton />
                  <Skeleton width={'60%'} />
                </Card>
              </Grid>
            );
          })}
        {isLoading === false &&
          items.length !== 0 &&
          items.slice(1).map((item: any, index: number) => {
            return (
              <Grid
                key={`grid-item-${item.id}`}
                className={`${classes.item} card-wrapper`}
                item
                xs={12}
                sm={12}
                md={6}
                lg={3}
                xl={3}
                style={{ padding: '12px' }}
              >
                <Card
                  className="hover-zoom"
                  style={{ display: 'block', margin: '0px auto' }}
                >
                  <RouterLink
                    to={`/character/${item.id}`}
                    style={{ textDecoration: 'none', color: '#000' }}
                  >
                    <div
                      className={`${classes.itemImage}`}
                      style={{ backgroundColor: `${item.parts[1].color}30` }}
                    >
                      <LazyLoadImage
                        alt={''}
                        height={'200px'}
                        src={`${PEET_NFT_SERVER}/characters/view/${item.id}.svg`}
                        style={{ display: 'block', margin: '0px auto' }}
                      />
                    </div>

                    <CardContent className="hover-bg-color">
                      <Typography
                        gutterBottom
                        variant="h5"
                        component="div"
                        style={{ textTransform: 'capitalize' }}
                      >
                        {item.name}
                      </Typography>
                      <Typography
                        component="h4"
                        variant="body2"
                        style={{ textTransform: 'capitalize', marginBottom: '20px' }}
                      >
                        {item.masterChar.name.replace(/[_-]/g, ' ')}
                      </Typography>
                    </CardContent>
                  </RouterLink>
                  <CardContent>
                    <LoadingButton
                      disabled
                      fullWidth
                      id={`suggest_adopt_link_${item.id}`}
                      className={`suggest_adopt_link_${item.id}`}
                      loadingPosition="start"
                      variant="contained"
                      style={{ fontSize: '20px' }}
                      startIcon={
                        <img
                          src={heartIcon}
                          id={`suggest_adopt_link_icon_${item.id}`}
                          className={`suggest_adopt_link_icon_${item.id}`}
                          alt=""
                        />
                      }
                      loading={item.isClicked}
                      onClick={() => {
                        adoptRandomClick(item.id);
                      }}
                    >
                      {/* Donate ${item.masterChar.minAmount} to Adopt */}
                      This campaign has ended.
                    </LoadingButton>
                  </CardContent>
                </Card>
              </Grid>
            );
          })}
      </Grid>

      <Button
        href="/100-peets/gallery"
        size="large"
        variant="contained"
        color="info"
        style={{ fontSize: '28px', marginTop: '20px' }}
      >
        View more PEETs
      </Button>
    </HundredStraysWrapper>
  );
}
