import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Slide from '@mui/material/Slide';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import peetLogo from '../../images/peet.svg';
import logo from '../../images/logo.svg';
import { HeaderContainer, headerStyles } from './Header.style';
import HeaderMenus from './HeaderMenus';
import HamburgerButton from './HamburgerButton';

export default function ScrollHeader(props: any) {
  const classes = headerStyles();
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down('lg'));
  const { window } = props;

  const [isMobileMenuOpen, setisMobileMenuOpen] = useState<boolean>(false);

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined
  });

  return (
    <Slide
      id="scroll-header-slide"
      appear={false}
      direction="down"
      in={trigger}
      style={{ width: '100%' }}
    >
      <div id="scroll-header-wrapper" className={classes.scrollHeaderWrapper}>
        <HeaderContainer id="scroll-header-container">
          <Grid item flexGrow="1">
            <RouterLink
              to="/"
              style={{
                width: '140px',
                textDecoration: 'none',
                color: '#fff',
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'nowrap',
                alignItems: 'center',
                justifyContent: 'flex-start'
              }}
            >
              <img src={peetLogo} className="App-logo" alt="" />
              <img src={logo} className="logo-text" alt="" />
            </RouterLink>
          </Grid>
          {isMobileView === false && <HeaderMenus />}
          {isMobileView === true && (
            <Grid item style={{ marginRight: '-14px' }}>
              <HamburgerButton
                isMobileMenuOpen={isMobileMenuOpen}
                setisMobileMenuOpen={setisMobileMenuOpen}
              />
            </Grid>
          )}
        </HeaderContainer>

        {isMobileView === true && isMobileMenuOpen === true && (
          <Grid
            container
            // spacing={'18px'}
            direction={'column'}
            flexWrap={'nowrap'}
            justifyContent={'center'}
            alignItems={'center'}
            style={{
              zIndex: '1',
              position: 'absolute',
              top: '64px',
              left: '0px',
              backgroundColor: '#320338',
              margin: '0px',
              padding: '0px 0px 8px 0px'
            }}
          >
            <HeaderMenus setisMobileMenuOpen={setisMobileMenuOpen} />
          </Grid>
        )}
      </div>
    </Slide>
  );
}
