import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';

export const ContentBody = styled('div')(({ theme }) => ({
  margin: '64px 0px 48px 0px',
  padding: '0px',
  width: '100%',
  [theme.breakpoints.down('xl')]: {
    maxWidth: '960px'
  },
  [theme.breakpoints.up('xl')]: {
    maxWidth: '1152px'
  }
}));

export const mainLayoutStyles = makeStyles({
  mainGrid: {
    margin: '0px',
    padding: '0px',
    minHeight: '100vh'
  },
  contentWrapper: {
    margin: '0px',
    padding: '0px',
    width: '100%',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    justifyContent: 'flex-start',
    alignItems: 'center',
    backgroundColor: '#111'
  }
});
