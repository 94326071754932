import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { Typography } from '@mui/material';

import { verifyUserByToken } from '../../services';
import { setAuthSuccess } from '../../features/auth.slice';

export default function VerifyUser() {
  const dispatch = useDispatch();
  let search = useLocation().search;

  const verifiedToken = new URLSearchParams(search).get('token') || '';

  const [isMountEnabled, setisMountEnabled] = useState(true);
  const [detailsData, setdetailsData] = useState<any>({});

  useEffect(() => {
    const getItems = async () => {
      const serviceRes = await verifyUserByToken(verifiedToken);
      let resData: any = {};

      if (!serviceRes.isSucceeded) {
        console.error('[Error] Verification failed.');
        resData.isVerified = false;
        resData.title = 'Verification Failed';
        resData.message = serviceRes.message;
      } else {
        localStorage.setItem('token', serviceRes.item.token);
        dispatch(setAuthSuccess(serviceRes.item));

        resData.isVerified = true;
        resData.title = 'Verification Completed';

        if (serviceRes.item.redirect_link) {
          resData.message = 'Redirecting to Checkout Page...';

          setTimeout(() => {
            window.open(serviceRes.item.redirect_link, '_self', 'noreferrer');
          }, 2000);
        } else {
          resData.message = 'Your email has been verified successfully.';
        }
      }

      setdetailsData(resData);
    };

    if (isMountEnabled) {
      if (verifiedToken !== '') {
        getItems();
      } else {
        setdetailsData({
          isVerified: false,
          title: 'Invalid Token',
          message: 'Invalid token or token not found.'
        });
      }
    }
    return () => {
      setisMountEnabled(false);
      // axiosCancelSource.cancel();
    };
  }, [isMountEnabled, verifiedToken, dispatch]);

  return (
    <Grid
      container
      component={Paper}
      alignItems={'center'}
      justifyContent={'center'}
      // style={{ height: '100vh' }}
    >
      <Paper
        variant="outlined"
        style={{
          margin: '20px',
          padding: '0px',
          minWidth: '300px',
          minHeight: '300px',
          display: 'flex',
          flexDirection: 'column',
          flexWrap: 'nowrap',
          alignContent: 'center',
          justifyContent: 'flex-start',
          alignItems: 'stretch'
        }}
      >
        <h1 style={{ textAlign: 'center' }}>{detailsData.title}</h1>
        <Grid
          id="signup-grid-container"
          container
          style={{
            margin: '0px',
            padding: '12px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'stretch'
          }}
        >
          <Typography style={{ margin: '12px 0px' }}>{detailsData.message}</Typography>
        </Grid>
      </Paper>
    </Grid>
  );
}
