import React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { FaqWrapper } from './Faq.style';

export default function PageNotFound() {
  const faqlist = {
    marginBottom: '40px',
    fontFamily: 'Arial',
    borderBottom: '1px dashed #ccc'
  };
  return (
    <FaqWrapper>
      <Grid container>
        <Grid item lg={9}>
          <Typography
            component="h1"
            variant="h2"
            color="white"
            gutterBottom
            style={{ fontSize: '3em' }}
          >
            Frequently Asked Questions
          </Typography>
          <Box
            sx={{
              backgroundColor: '#FFF',
              p: { xs: 3, md: 6 }
            }}
          >
            <Typography component="div" variant="body1" style={{ color: '' }}>
              <h2>How to get a free NFT?</h2>
              <ul style={faqlist}>
                <li>
                  <h3>Make a Donation</h3>
                  <div className="divider"></div>
                  <p>1. Donate SGD 30 to support the Animal Welfare cause.</p>
                  <p>
                    2. Upon completion, you will receive the following in a{' '}
                    <strong>Donation Acknowledgement</strong> email:<br></br>- Receipt
                    <br></br>- Redemption code<br></br>- Link to Redemption form<br></br>
                  </p>
                </li>
                <li>
                  <h3>Complete Redemption Form</h3>
                  <p>
                    Provide your MetaMask wallet address.<br></br>Don’t have one?{' '}
                    <a target={'_blank'} rel="noreferrer" href="https://metamask.io/">
                      Click here to create.
                    </a>{' '}
                    Need{' '}
                    <a
                      target={'_blank'}
                      rel="noreferrer"
                      href="https://www.youtube.com/watch?v=GNPz-Dv5BjM"
                    >
                      help?
                    </a>
                  </p>
                </li>
                <li>
                  <h3>How to view or download my NFTs?</h3>
                  <p>
                    Connect your MetaMask wallet to <strong>OpenSea</strong> to view or
                    download your NFTs.
                  </p>
                  <p>
                    Not connected?{' '}
                    <a
                      target={'_blank'}
                      rel="noreferrer"
                      href="https://www.youtube.com/watch?v=7pmA02zY8Ag"
                    >
                      Click here
                    </a>{' '}
                    to find out how to connect your MetaMask wallet to OpenSea.
                  </p>
                </li>
              </ul>

              <h2>What is MetaMask?</h2>
              <ul style={faqlist}>
                <li>
                  MetaMask is a global community of developers and designers dedicated to
                  making the world a better place with blockchain technology.
                  <p>
                    <a
                      target={'_blank'}
                      rel="noreferrer"
                      href="https://metamask.io/about/"
                    >
                      Click here
                    </a>{' '}
                    to learn more about MetaMask.
                  </p>
                </li>
              </ul>
              <h2>What is OpenSea?</h2>
              <ul style={faqlist}>
                <li>
                  OpenSea is the first and largest marketplace for NFTs.
                  <p>
                    <a target={'_blank'} rel="noreferrer" href="https://opensea.io/about">
                      Click here
                    </a>{' '}
                    to learn more about OpenSea.
                  </p>
                </li>
              </ul>
              <h2>Which are the charities under Animal Welfare?</h2>
              <ul style={faqlist}>
                <li>
                  The charities you will be supporting under{' '}
                  <strong>Animal Welfare</strong> are:
                  <ol>
                    <li>Society for the Prevention of Cruelty to Animals (SPCA)</li>
                    <li>SOSD Singapore</li>
                    <li>Animal Concerns Research and Education Society (ACRES)</li>
                  </ol>
                  <p>
                    *These charities will get an even share of your $30 donation - $10 per
                    charity.
                  </p>
                  <p>
                    <a
                      target={'_blank'}
                      rel="noreferrer"
                      href="https://hello.chatrity.com/charities"
                    >
                      Click here
                    </a>{' '}
                    to learn more about our Charity Partners.
                  </p>
                </li>
              </ul>

              <h2>About Non-Fungible Tokens (NFTs)</h2>
              <ul style={faqlist}>
                <li>
                  <h3>What is a Non-Fungible Token (NFT)?</h3>
                  <p>
                    An NFT is a digital asset that represents real-world objects like art,
                    music, in-game items and videos.{' '}
                  </p>
                  <p>
                    Essentially, NFTs are like physical collector’s items, only digital.
                    So instead of getting an actual oil painting to hang on the wall, the
                    buyer gets a digital file instead.
                  </p>
                </li>
                <li>
                  <h3>What does it mean to own an NFT?</h3>
                  <p>
                    NFTs are generally one of a kind, or at least one of a very limited
                    run, and have unique identifying codes.
                  </p>
                  <p>
                    NFT allows the buyer to own the original item. Not only that, it
                    contains built-in authentication, which serves as proof of ownership.
                  </p>
                </li>
              </ul>

              <h2>Can I sell my Peet NFT?</h2>
              <ul style={faqlist}>
                <li>
                  <p>
                    Of course! You may put your NFT up for sale or auction on OpenSea.
                  </p>
                </li>
              </ul>
            </Typography>
          </Box>

          <Box
            sx={{
              backgroundColor: '#F0F6EF',
              p: { xs: 3, md: 6 },
              mt: 20
            }}
          >
            <h2>Got lost along the way, who shall I contact?</h2>
            <ul className="">
              <li>
                <p>
                  Making sure you are able to donate and redeem your NFT is important to
                  us, and we will be happy to assist. Feel free to contact us on WhatsApp
                  at <strong>+65 8749 8586</strong> or drop us an email at{' '}
                  <strong>support@chatrity.com</strong> whenever you need help.
                </p>
              </li>
            </ul>
          </Box>
        </Grid>
      </Grid>
    </FaqWrapper>
  );
}
