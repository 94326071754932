import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';

export const DetailsWrapper = styled('div')(({ theme }) => ({
  padding: '80px 5%',
  margin: '0px',
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  boxSizing: 'border-box',
  backgroundColor: '#0f0330'
}));

export const detailsStyles = makeStyles({
  paperContainer: {
    width: '100%',
    padding: '0',
    margin: '0px',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    alignContent: 'center',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  shareContainer: {
    padding: '8px',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    justifyContent: 'flex-end',
    alignItems: 'center',
    position: 'absolute',
    top: '0px',
    right: '0px'
  },
  shareButton: {
    margin: '4px',
    height: '32px',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignItems: 'center'
  }
});
