import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
// import axios from 'axios';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import FavoriteIcon from '@mui/icons-material/Favorite';
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { TableContainer } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { PEET_NFT_SERVER } from '../../constants';
import { ECampaignTypes } from '../../enum';
import { getCharacter, adoptCharacter } from '../../services';
import { markdownText } from '../../utils';
import { DetailsWrapper, detailsStyles } from './CharacterDetails.style';
import CharacterSkeleton from './CharacterSkeleton';
import nextsteps from '../../images/nextsteps.png';
import CustomMeta from '../../components/CustomMeta';
import {
  authSelector,
  // setPeetId,
  // setCurrentUrl,
  setLoading
} from '../../features/auth.slice';
// import { setOpenLoginDialog } from '../../features/dialogBox.slice';
import CharacterShare from './CharacterShare';
import { getTimestamptString } from '../../utils';

export default function CharacterDetails() {
  const classes = detailsStyles();
  const dispatch = useDispatch();

  let { id } = useParams();

  const {
    // isAuth,
    isLoading
  } = useSelector(authSelector);

  const [isMountEnabled, setisMountEnabled] = useState(true);
  const [item, setitemDetails] = useState<any>({});
  const [isAdoptInProgress, setisAdoptInProgress] = useState<boolean>(false);
  const [isDonateButtonEnabled, setisDonateButtonEnabled] = useState<boolean>(true);

  const handleAdoptClick = async () => {
    try {
      // const token = localStorage.getItem('token');
      // if (!token) {
      //   setisDialogOpen(true);
      //   return;
      // }

      // if (!isAuth) {
      //   dispatch(setCurrentUrl(window.location.href));
      //   const charId: number = id ? parseInt(id) : 0;
      //   dispatch(setPeetId(charId));
      //   dispatch(setOpenLoginDialog({ title: '', message: '', type: 'LOGIN' }));
      //   return;
      // }

      setisAdoptInProgress(true);

      const adoptPayload = {
        characterId: id
      };

      const itemsResponse = await adoptCharacter(adoptPayload);
      const detailsData = itemsResponse.item;

      setisAdoptInProgress(false);

      if (_.isEmpty(detailsData) || !detailsData.url) {
        console.error('[Error] no adopt data or token');
        return;
      }

      // NOTE: e.g
      // staging url: https://donate.chatrity.com/checkout-form?t=spk6LbzhU3IT9Gfe2D5h
      // prod url: https://payments.chatrity.com/checkout-form?t=spk6LbzhU3IT9Gfe2D5h
      window.open(detailsData.url, '_self', 'noreferrer');
    } catch (error) {
      setisAdoptInProgress(false);
      console.error('[Error] in handleAdoptClick', error);
    }
  };

  useEffect(() => {
    // const axiosCancelSource = axios.CancelToken.source();
    // const axiosCancelToken = axiosCancelSource.token;

    const getItems = async () => {
      dispatch(setLoading(true));
      const itemsResponse = await getCharacter(
        // axiosCancelToken,
        id
      );
      if (_.isEmpty(itemsResponse.item)) {
        console.error('[Error] No charater found.');
      } else {
        setitemDetails(itemsResponse.item);
      }
      dispatch(setLoading(false));
    };

    if (isMountEnabled) {
      getItems();
    }
    return () => {
      setisMountEnabled(false);
      // axiosCancelSource.cancel();
    };
  }, [isMountEnabled, id, dispatch]);

  useEffect(() => {
    if (!_.isEmpty(item)) {
      let isActive = true;
      switch (item.campaignCategory) {
        case ECampaignTypes.Peace:
        case ECampaignTypes.TrybeForYouth:
        case ECampaignTypes.TrybeYouthRising:
          isActive = false;
          break;
        default:
          isActive = true;
          break;
      }
      setisDonateButtonEnabled(isActive);
    }
  }, [item]);

  return (
    <DetailsWrapper id="details-wrapper">
      {isLoading === false && _.isEmpty(item) === false && (
        <CustomMeta title={item.metaTitle} description={item.metaDescription} />
      )}
      {isLoading === false && _.isEmpty(item) === true && (
        <Typography
          component={'p'}
          variant="h5"
          color="white"
          style={{ padding: '0px', margin: '48px 0px' }}
        >
          No PEET found
        </Typography>
      )}

      {isLoading === true && <CharacterSkeleton />}

      {isLoading === false && _.isEmpty(item) === false && (
        <Paper id="image-paper-container" className={classes.paperContainer}>
          <Grid
            id="image-grid-container"
            container
            style={{
              backgroundColor: `${item.peetImgBgColor}30`,
              position: 'relative',
              height: '600px'
            }}
          >
            {item.isPeetAdopted === true && item.campaignCategory === '100strays' && (
              <Chip
                icon={
                  <FavoriteIcon
                    fontSize="small"
                    style={{
                      color: '#fff'
                    }}
                  />
                }
                label="ADOPTED"
                style={{
                  position: 'absolute',
                  top: '36px',
                  left: '-11px',
                  transform: 'rotate(317deg)',
                  backgroundColor: '#D29912',
                  fontSize: '18px',
                  color: '#fff',
                  fontWeight: 'bold'
                }}
              />
            )}

            <CharacterShare />

            <LazyLoadImage
              alt={''}
              src={`${PEET_NFT_SERVER}/characters/view/${id}.svg`}
              style={{
                display: 'block',
                margin: '0px auto',
                height: '100%',
                width: '100%'
              }}
            />
          </Grid>
          <Grid
            id="image-content-container"
            maxWidth={'1024px'}
            container
            direction={'row'}
            justifyContent={'center'}
            style={{ padding: '16px 16px 16px 16px' }}
          >
            <Grid
              item
              flexGrow={1}
              xs={12}
              sm={12}
              md={12}
              lg={9}
              style={{ padding: '0px 0px 24px 0px' }}
            >
              <Typography
                variant="h2"
                style={{ padding: '0px 0px 12px 0px', textTransform: 'capitalize' }}
              >
                {item.peetName}
              </Typography>
              {item.isPeetAdopted === true && (
                <>
                  {item.owners.length > 1 && (
                    <>
                      {item.owners[0].opensea_link && (
                        <Button
                          href={item.owners[0].opensea_link}
                          target={'_blank'}
                          size="small"
                          variant="contained"
                          color="secondary"
                          style={{ margin: '8px 0px' }}
                        >
                          View on OpenSea
                        </Button>
                      )}
                      <TableContainer
                        component={Paper}
                        style={{ maxWidth: '680px', maxHeight: '320px' }}
                      >
                        <Table stickyHeader aria-label="owners table">
                          <TableHead>
                            <TableRow>
                              <TableCell>Adopted by</TableCell>
                              <TableCell>Wallet</TableCell>
                              <TableCell>Adopted on</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {item.owners.map((row: any, index: number) => (
                              <TableRow
                                key={`owner-row-${index}`}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                              >
                                <TableCell component="th" scope="row">
                                  {row.user && row.user.name}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  {row.wallet && `${row.wallet}`}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  {getTimestamptString(
                                    row.createdAt,
                                    'DD/MM/YYYY hh:mmA'
                                  )}
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </>
                  )}
                  {item.owners.length === 1 && (
                    <>
                      <Typography>
                        <strong>Adopted By: </strong>
                        {item.owners[0].user && item.owners[0].user.name}
                      </Typography>
                      <Typography>
                        <strong>Wallet: </strong>
                        <small>
                          {item.owners[0].wallet && `${item.owners[0].wallet}`}
                        </small>
                      </Typography>
                      <Typography>
                        <strong>Adopted on: </strong>{' '}
                        <small>
                          {getTimestamptString(
                            item.owners[0].createdAt,
                            'DD/MM/YYYY hh:mmA'
                          )}
                        </small>
                      </Typography>
                      {item.owners[0].opensea_link && (
                        <Button
                          href={item.owners[0].opensea_link}
                          target={'_blank'}
                          size="small"
                          variant="contained"
                          color="secondary"
                          style={{ marginTop: '20px' }}
                        >
                          View on OpenSea
                        </Button>
                      )}
                    </>
                  )}
                  <Divider style={{ marginBottom: '20px', marginTop: '10px' }} />
                </>
              )}
              <Grid item lg={11}>
                <Typography component="h4">
                  <strong>Type: </strong>
                </Typography>
                <Typography
                  component="h4"
                  variant="body2"
                  style={{ textTransform: 'capitalize', margin: '14px 0px 20px 0px' }}
                >
                  {item.peetType}
                </Typography>

                <Typography component="h4">
                  <strong>About: </strong>
                </Typography>
                <Typography
                  variant="body2"
                  style={{ padding: '0px 24px 24px 0px' }}
                  dangerouslySetInnerHTML={{ __html: markdownText(item.peetAbout) }}
                />
              </Grid>
              {item.isPeetAdopted === false && (
                <Grid item lg={11} style={{ textAlign: 'center' }}>
                  <img src={nextsteps} alt="" style={{ width: '100%' }} />
                </Grid>
              )}
              <Grid item lg={11} style={{ textAlign: 'center' }}>
                <Card
                  style={{
                    display: 'block',
                    margin: '10px auto',
                    backgroundColor: '#F3EDDE'
                  }}
                >
                  <CardContent style={{ padding: '20px 40px' }}>
                    {item.footerText !== '' && (
                      <Typography
                        component="div"
                        variant="body1"
                        style={{
                          marginBottom: '4px',
                          padding: '12px 0px',
                          wordBreak: 'break-word'
                        }}
                      >
                        {item.footerText}
                      </Typography>
                    )}
                    <Typography
                      component="div"
                      variant="body1"
                      style={{ padding: '12px 0px', wordBreak: 'break-word' }}
                    >
                      For more information visit{' '}
                      <a
                        href="https://hello.chatrity.com/charities"
                        target={'_blank'}
                        rel="noreferrer"
                        style={{ color: '#2196f3' }}
                      >
                        https://hello.chatrity.com/charities
                      </a>
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={10}
              lg={3}
              style={{
                border: '1px solid #dd53ff',
                padding: '16px',
                backgroundColor: '#1f0422',
                color: '#FFF'
              }}
            >
              {item.donateDescription !== '' && (
                <Typography variant="body2" align="center" marginBottom="10px">
                  {item.donateDescription}
                </Typography>
              )}

              <Typography component="h4" align="center">
                <a
                  href="https://hello.chatrity.com/charities"
                  target={'_blank'}
                  rel="noreferrer"
                  style={{ color: '#2196f3' }}
                >
                  <strong>Click here to view charites.</strong>
                </a>
              </Typography>
              <Typography
                variant="h3"
                align="center"
                marginBottom="10px"
                marginTop="10px"
              >
                <span
                  style={{
                    fontWeight: 'light',
                    fontSize: '1.2rem'
                  }}
                >
                  SGD
                </span>
                {item.minAmount}
              </Typography>
              <Stack direction="row" spacing={1} style={{ width: '100%' }}>
                <Chip
                  label="This campaign has ended."
                  variant="outlined"
                  style={{ width: '100%', color: '#FFF' }}
                />
              </Stack>
              {/* Ended all campaigns */}
              {/* {isDonateButtonEnabled === false && (
                <Stack direction="row" spacing={1} style={{ width: '100%' }}>
                  <Chip
                    label="This campaign has ended."
                    variant="outlined"
                    style={{ width: '100%', color: '#FFF' }}
                  />
                </Stack>
              )} */}
              {/* {isDonateButtonEnabled === true && (
                <LoadingButton
                  className={`adopt_link ${
                    item.isPeetAvailable ? '' : 'dark-adopt-button-disabled'
                  }`}
                  fullWidth
                  loadingPosition="start"
                  variant="contained"
                  startIcon={<FavoriteIcon id="adopt_link_icon" />}
                  loading={isAdoptInProgress}
                  disabled={!item.isPeetAvailable}
                  onClick={handleAdoptClick}
                >
                  {item.donateButtonText}
                </LoadingButton>
              )} */}
              <Typography variant="body2" align="center" style={{ marginTop: '10px' }}>
                Donation via PayPal or Paynow
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      )}
      {item.campaignCategory === '100strays' && (
        <Button
          href="/100-peets/gallery"
          size="large"
          variant="contained"
          color="info"
          style={{ fontSize: '28px', marginTop: '20px', width: '100%' }}
        >
          View more PEETs
        </Button>
      )}
      {item.campaignCategory === 'sosd10' && (
        <Button
          href="/sosdbig10/gallery"
          size="large"
          variant="contained"
          color="info"
          style={{ fontSize: '28px', marginTop: '20px', width: '100%' }}
        >
          View more SOSD NFTs
        </Button>
      )}
    </DetailsWrapper>
  );
}
