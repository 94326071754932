import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import './App.css';
import PrivateRoutes from './privateRoutes';
import MainLayout from './layouts/MainLayout';
import PageNotFound from './pages/pageNotFound';
import Home from './pages/home';
import HundredGallery from './pages/hundredGallery';
import HundredStrays from './pages/hundredStrays';
import Peace from './pages/peace';
import CharacterDetails from './pages/characterDetails';
import AboutUs from './pages/aboutUs';
import Charities from './pages/charities';
import Faq from './pages/faq';
import Login from './pages/login';
import Profile from './pages/profile';
import SignUp from './pages/signUp';
import Sosd from './pages/sosd';
import Trybe from './pages/trybe';
import TrybeRising from './pages/trybeRising';
import SosdGallery from './pages/sosdGallery';
import CompleteSignUp from './pages/completeSignUp';
import VerifyUser from './pages/verifyUser/VerifyUser';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<MainLayout />}>
          <Route index element={<Home />} />
          <Route path="/character/:id" element={<CharacterDetails />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/charities" element={<Charities />} />
          <Route path="/100-peets/gallery" element={<HundredGallery />} />
          <Route path="/100-peets" element={<HundredStrays />} />
          <Route path="/peace" element={<Peace />} />
          <Route path="/sosdbig10" element={<Sosd />} />
          <Route path="/sosdbig10/gallery" element={<SosdGallery />} />
          <Route path="/trybe4youth" element={<Trybe />} />
          <Route path="/trybeyouthrising" element={<TrybeRising />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/complete-signup" element={<CompleteSignUp />} />
          <Route path="/verify-user" element={<VerifyUser />} />

          <Route path="/" element={<PrivateRoutes />}>
            <Route path="/profile" element={<Profile />} />
          </Route>
        </Route>
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
