import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';
import sosdBackground from '../../images/bg-sosd.jpg';

export const SosdWrapper = styled('div')(({ theme }) => ({
  padding: '80px 5%',
  margin: '0px',
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  boxSizing: 'border-box',
  backgroundImage: `url(${sosdBackground})`,
  backgroundColor: '#182406',
  backgroundPosition: 'top center',
  backgroundRepeat: 'no-repeat'
}));

export const sosdStyles = makeStyles({
  item: {
    width: '100%',
    height: 'auto',
    margin: '0px',
    padding: '0px',
    color: 'black'
  },
  itemImage: {
    width: '100%'
  }
});
