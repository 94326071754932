import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import axios from 'axios';
import _ from 'lodash';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useSelector, useDispatch } from 'react-redux';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
// import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';

import { TrybeWrapper, trybeStyles } from './TrybeRising.style';
import { getTrybeItems, adoptCharacter } from '../../services';
import trybeRisingHeroBlack from '../../images/trybe-rising-hero-black.png';
import trybeAbout from '../../images/trybe-rising-des.png';
import { PEET_NFT_SERVER } from '../../constants';
import heartIcon from '../../images/heart.png';
import CustomMeta from '../../components/CustomMeta';

import {
  authSelector,
  // setPeetId,
  // setCurrentUrl,
  setLoading
} from '../../features/auth.slice';
// import { setOpenLoginDialog } from '../../features/dialogBox.slice';

export default function TrybeYouth() {
  const classes = trybeStyles();
  const dispatch = useDispatch();

  const {
    // isAuth,
    isLoading
  } = useSelector(authSelector);

  const [isMountEnabled, setisMountEnabled] = useState(true);
  const [items, setitems] = useState([]);

  const handleDonateClick = async (id: number) => {
    try {
      // if (!isAuth) {
      //   dispatch(setCurrentUrl(window.location.href));
      //   dispatch(setPeetId(id));
      //   dispatch(setOpenLoginDialog({ title: '', message: '', type: 'LOGIN' }));
      //   return;
      // }

      const newItemList = [...items];
      newItemList.forEach((i: any) => {
        if (i.id === id) {
          i.isClicked = true;
        }
      });
      setitems(newItemList);

      const adoptPayload = {
        characterId: id
      };
      const itemsResponse = await adoptCharacter(adoptPayload);
      const detailsData = itemsResponse.item;

      if (_.isEmpty(detailsData) || !detailsData.url) {
        console.error('[Error] no adopt data or token');
        return;
      }

      window.open(detailsData.url, '_self', 'noreferrer');
    } catch (error) {
      console.error('[Error] in handleDonateClick', error);
    } finally {
      const newItemList = [...items];
      newItemList.forEach((i: any) => {
        if (i.id === id) {
          i.isClicked = false;
        }
      });
      setitems(newItemList);
    }
  };

  useEffect(() => {
    const axiosCancelSource = axios.CancelToken.source();
    const axiosCancelToken = axiosCancelSource.token;

    const getItems = async () => {
      dispatch(setLoading(true));
      const itemsResponse = await getTrybeItems(axiosCancelToken, {
        status: 'available',
        itemsCount: 6,
        category: 'trybeyouthrising'
      });
      if (itemsResponse.items.length) {
        setitems(itemsResponse.items);
      } else {
        console.log('[Info] No Trybe item');
      }
      dispatch(setLoading(false));
    };

    if (isMountEnabled) {
      getItems();
    }

    return () => {
      setisMountEnabled(false);
      axiosCancelSource.cancel();
    };
  }, [isMountEnabled, dispatch]);

  return (
    <TrybeWrapper
      id="trybe-wrapper"
      sx={{ backgroundSize: { xs: 'auto', md: 'contain' } }}
    >
      <CustomMeta
        title={`It takes a Community to journey with a Youth.`}
        imageUrl={`https://peet.me${trybeRisingHeroBlack}`}
        description={`This series of limited edition Trybe NFTs envelopes the spirit of community-building and togetherness. It highlights the importance of community effort in helping Trybe's youth overcome their adversities and pursue their dreams. Co-created with Trybe youth and staff, and youth advocates, we have designed a series of Limited Edition Trybe NFTs specially for all YR Month donors*.`}
      />

      <Grid container marginBottom={'48px'}>
        <Grid
          item
          md={12}
          lg={7}
          container
          justifyContent={'flex-start'}
          alignItems={'center'}
        >
          <Typography
            component="h1"
            variant="h2"
            color="#FFF"
            gutterBottom
            sx={{ fontSize: { xs: '2.0em', md: '2.3em' } }}
          >
            It takes a Community to journey with a Youth.
          </Typography>
          <Typography variant="h6" paragraph sx={{ color: { xs: '#FFF' } }}>
            This series of limited edition Trybe NFTs envelopes the spirit of
            community-building and togetherness. It highlights the importance of community
            effort in helping Trybe's youth overcome their adversities and pursue their
            dreams. Co-created with Trybe youth and staff, and youth advocates, we have
            designed a series of Limited Edition Trybe NFTs specially for all YR Month
            donors*.
          </Typography>
        </Grid>

        <Grid
          item
          container
          justifyContent={'center'}
          alignItems={'center'}
          xs={12}
          sm={12}
          md={12}
          lg={5}
        >
          <Grid
            item
            sm={12}
            md={7}
            lg={11}
            container
            justifyContent={'center'}
            alignItems={'center'}
          >
            <LazyLoadImage
              alt={''}
              src={trybeRisingHeroBlack}
              style={{
                width: '100%',
                maxWidth: '340px'
              }}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} textAlign="center">
        <Typography component="h1" variant="h2" color="white" gutterBottom>
          NFT Gallery
        </Typography>
      </Grid>

      <Grid
        container
        style={{
          margin: '0px 0px 12px 0px'
        }}
      >
        {isLoading === false &&
          items.length !== 0 &&
          items.map((item: any, index: number) => {
            return (
              <Grid
                key={`grid-item-${item.id}`}
                className={`${classes.item} card-wrapper`}
                item
                xs={12}
                sm={12}
                md={6}
                lg={4}
                xl={4}
                style={{ padding: '12px' }}
              >
                <Card
                  className="hover-zoom"
                  style={{ display: 'block', margin: '0px auto' }}
                >
                  <RouterLink
                    to={`/character/${item.id}`}
                    style={{ textDecoration: 'none', color: '#000' }}
                  >
                    <div
                      className={classes.itemImage}
                      style={{ backgroundColor: `${item.parts[1].color}30` }}
                    >
                      <LazyLoadImage
                        alt={''}
                        height={'320px'}
                        src={`${PEET_NFT_SERVER}/characters/view/${item.id}.svg`}
                        style={{ display: 'block', margin: '0px auto' }}
                      />
                    </div>
                    <CardContent className="hover-bg-color">
                      <Typography
                        gutterBottom
                        variant="h5"
                        component="div"
                        style={{ textTransform: 'capitalize' }}
                      >
                        {item.name}
                      </Typography>
                      <Typography
                        component="h4"
                        variant="body2"
                        style={{ textTransform: 'capitalize', marginBottom: '20px' }}
                      >
                        {item.masterChar.name.replace(/[_-]/g, ' ')}
                      </Typography>
                    </CardContent>
                  </RouterLink>
                  <CardContent>
                    <LoadingButton
                      disabled
                      fullWidth
                      id={`suggest_adopt_link_${item.id}`}
                      className={`suggest_adopt_link_${item.id}`}
                      loadingPosition="start"
                      variant="contained"
                      style={{ fontSize: '20px' }}
                      startIcon={
                        <img
                          src={heartIcon}
                          id={`suggest_adopt_link_icon_${item.id}`}
                          className={`suggest_adopt_link_icon_${item.id}`}
                          alt=""
                        />
                      }
                      loading={item.isClicked}
                      onClick={() => {
                        handleDonateClick(item.id);
                      }}
                    >
                      {/* Donate ${item.masterChar.minAmount} */}
                      This campaign has ended.
                    </LoadingButton>
                  </CardContent>
                </Card>
              </Grid>
            );
          })}

        {isLoading === true &&
          [1, 2, 3].map((index: number) => {
            return (
              <Grid
                key={`skeleton-item-${index}`}
                className={classes.item}
                item
                xs={12}
                sm={12}
                md={4}
                lg={4}
                xl={4}
                style={{
                  padding: '20px'
                }}
              >
                <Card
                  key={`random-card-skeleton`}
                  style={{
                    display: 'block',
                    margin: '0px',
                    padding: '0px 12px 12px 12px'
                  }}
                >
                  <Skeleton width={'100%'} height={'200px'} />
                  <Skeleton />
                  <Skeleton />
                  <Skeleton width={'60%'} />
                </Card>
              </Grid>
            );
          })}

        {isLoading === false && items.length === 0 && (
          <Typography
            component={'p'}
            variant="h5"
            color="white"
            align="center"
            style={{ padding: '0px', margin: '48px 0px' }}
          >
            No Trybe Data
          </Typography>
        )}
      </Grid>

      {/* 
      <Button
        href="/sosdbig10/gallery"
        size="large"
        variant="contained"
        color="info"
        style={{ fontSize: '28px', margin: '12px 0px 48px 0px' }}
      >
        View more Trybe NFTs
      </Button> 
      */}

      <Grid>
        <Card
          style={{
            display: 'block',
            backgroundColor: '#FFFBE6',
            height: '100%',
            padding: '20px'
          }}
        >
          <Grid container spacing={1}>
            <Grid item lg={6} style={{ paddingRight: '20px', textAlign: 'justify' }}>
              <h2 style={{ marginTop: '0px' }}>About Campaign</h2>
              <Typography
                variant="subtitle1"
                textAlign={'left'}
                color="inherit"
                paragraph
              >
                Traumatic events and adversities in life can negatively affect the
                lifelong development of a youth. Oftentimes, these youth have no one to
                turn to and hence, misguided by their environment, negative influences and
                people. Community plays a vital role in every youth's life. Especially
                when a youth does not have anyone to go to for support, the community must
                come together to provide help, support and even, opportunities. This Youth
                Rising Month (YR), we have joined Trybe to create a safe and accepting
                community for youth! Co-created with Trybe youth and staff, and youth
                advocates, we have designed a series of Limited Edition Trybe NFTs
                specially for all YR Month donors*. YR Month donors will be able to choose
                an NFT of choice when they donate $10 or more. (*donors who donated
                through{' '}
                <a
                  href="https://peet.me/trybeyouthrising"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  https://peet.me/trybeyouthrising
                </a>
                ). It has been an arduous and purposeful journey for Trybe and we know
                that more can be done for the youth of Singapore. Your donations will help
                fund Trybe's programmes that provide outreach, intervention,
                rehabilitation and reintegration support and a complete ecosystem of care
                to youth. Your generosity will help strengthen the wings of our young ones
                for them to take flight in the future. (Visit{' '}
                <a href="https://www.trybe.org/" target={'_blank'} rel="noreferrer">
                  www.trybe.org
                </a>{' '}
                to find out more about what Trybe does).
              </Typography>
              <hr />
              <h2>Who is Chatrity?</h2>
              <Typography
                variant="subtitle1"
                textAlign={'left'}
                color="inherit"
                paragraph
              >
                Chatrity is a secure fundraising channel for donors to give conveniently
                to a charity organisation (eg. Trybe) or a cause they care about (eg.
                Children & Youth) on social messaging platforms like Telegram (try us out
                at{' '}
                <a href="https://t.me/ChatrityBot" target={'_blank'} rel="noreferrer">
                  https://t.me/ChatrityBot
                </a>
                ) ). We help our partner charities tell their story through our smart
                chatbot interface as well as the creation and distribution of NFT-based
                digital assets. Find out more about us at{' '}
                <a href="https://hello.chatrity.com/" target={'_blank'} rel="noreferrer">
                  https://hello.chatrity.com/
                </a>
                .
              </Typography>
            </Grid>
            <Grid
              item
              container
              justifyContent={'center'}
              alignItems={'flex-start'}
              lg={6}
            >
              <img src={trybeAbout} style={{ width: '100%' }} alt="" />
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </TrybeWrapper>
  );
}
