import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';

export const HeaderContainer = styled('div')(({ theme }) => ({
  margin: '0px',
  padding: '0px',
  height: '64px',
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  justifyContent: 'flex-start',
  alignItems: 'center',
  width: '100%'
  // [theme.breakpoints.down('xl')]: {
  //   maxWidth: '960px'
  // },
  // [theme.breakpoints.up('xl')]: {
  //   maxWidth: '1152px'
  // }
}));

export const headerStyles = makeStyles({
  staticHeaderWrapper: {
    margin: '0px',
    padding: '0px 24px',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'center',
    alignItems: 'center'
  },
  scrollHeaderWrapper: {
    margin: '0px',
    padding: '0px 24px',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'fixed',
    zIndex: '1100',
    top: '0px',
    left: 'auto',
    right: 'auto',
    backgroundColor: '#320338'
  },
  gridTitleItem: {
    flexGrow: 1,
    padding: '0px',
    marginLeft: '12px',
    textDecoration: 'none',
    color: '#fff'
  },
  gridMenuItem: {
    padding: '8px 12px',
    fontSize: '11px',
    textTransform: 'uppercase',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignContent: 'center',
    justifyContent: 'center',
    alignItems: 'center'
  }
});
