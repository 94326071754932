import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';

import { getDonationHistory } from '../../services/user.service';
import { getTimestamptString } from '../../utils';

export default function DonationHistory() {
  const [isLoading, setisLoading] = useState<boolean>(false);
  const [isMountEnabled, setisMountEnabled] = useState(true);
  const [items, setitems] = useState<any>({});

  useEffect(() => {
    const getItems = async () => {
      setisLoading(true);
      const serviceRes = await getDonationHistory();

      if (serviceRes.isSucceeded) {
        setitems(serviceRes.items);
      } else {
        console.error('[Error] No Donation History.');
      }

      setisLoading(false);
    };

    if (isMountEnabled) {
      getItems();
    }
    return () => {
      setisMountEnabled(false);
    };
  }, [isMountEnabled]);

  return (
    <Card sx={{ marginBottom: '20px' }}>
      <CardHeader title="Donation History" />
      <CardContent>
        {isLoading === false && items.length === 0 && (
          <Typography
            component={'p'}
            variant="h5"
            align="center"
            style={{ padding: '0px', margin: '48px 0px' }}
          >
            No donation data
          </Typography>
        )}
        <TableContainer component={Paper}>
          {isLoading === true && (
            <>
              <Skeleton height={'50px'} />
              <Skeleton height={'50px'} />
              <Skeleton height={'50px'} />
              <Skeleton height={'50px'} />
            </>
          )}
          {isLoading === false && items.length !== 0 && (
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Receipt Number</TableCell>
                  <TableCell align="right">Donation Amount</TableCell>
                  <TableCell align="right">Cause</TableCell>
                  <TableCell align="right">Date</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!_.isEmpty(items) &&
                  items.map((item: any) => (
                    <TableRow
                      key={item.receipt_number}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {item.receipt_number}
                      </TableCell>
                      <TableCell align="right">${item.amount}</TableCell>
                      <TableCell align="right">{item.typeName}</TableCell>
                      <TableCell align="right">
                        {getTimestamptString(item.createdAt, 'DD/MM/YYYY hh:mmA')}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          )}
        </TableContainer>
      </CardContent>
    </Card>
  );
}
