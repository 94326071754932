import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import axios from 'axios';
import _ from 'lodash';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useSelector, useDispatch } from 'react-redux';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';

import { PEET_NFT_SERVER } from '../../constants';
import { PeaceWrapper, peaceStyles } from './Peace.style';
import { getPeaceItems, adoptCharacter } from '../../services';
import heartIcon from '../../images/heart.png';
import heroBannerMain from '../../images/hero-banner-peace.png';
import peaceImage from '../../images/peace-image.jpg';
import peaceSign from '../../images/peace-sign.png';
import redCrossLogo from '../../images/red-cross-logo.png';
import CustomMeta from '../../components/CustomMeta';

import {
  authSelector,
  // setPeetId,
  // setCurrentUrl,
  setLoading
} from '../../features/auth.slice';
// import { setOpenLoginDialog } from '../../features/dialogBox.slice';

export default function Peace() {
  const classes = peaceStyles();
  const dispatch = useDispatch();

  const {
    // isAuth,
    isLoading
  } = useSelector(authSelector);

  const [isMountEnabled, setisMountEnabled] = useState(true);
  const [items, setitems] = useState([]);

  const handlePeaceDonate = async (id: number) => {
    try {
      // if (!isAuth) {
      //   dispatch(setCurrentUrl(window.location.href));
      //   dispatch(setPeetId(id));
      //   dispatch(setOpenLoginDialog({ title: '', message: '', type: 'LOGIN' }));
      //   return;
      // }

      const newItemList = [...items];
      newItemList.forEach((i: any) => {
        if (i.id === id) {
          i.isClicked = true;
        }
      });
      setitems(newItemList);

      const adoptPayload = {
        characterId: id
      };
      const itemsResponse = await adoptCharacter(adoptPayload);
      const detailsData = itemsResponse.item;

      if (_.isEmpty(detailsData) || !detailsData.url) {
        console.error('[Error] no adopt data or token');
        return;
      }

      window.open(detailsData.url, '_self', 'noreferrer');
    } catch (error) {
      console.error('[Error] in handlePeaceDonate', error);
    } finally {
      const newItemList = [...items];
      newItemList.forEach((i: any) => {
        if (i.id === id) {
          i.isClicked = false;
        }
      });
      setitems(newItemList);
    }
  };

  useEffect(() => {
    const axiosCancelSource = axios.CancelToken.source();
    const axiosCancelToken = axiosCancelSource.token;

    const getItems = async () => {
      dispatch(setLoading(true));
      const itemsResponse = await getPeaceItems(axiosCancelToken, {
        status: 'available',
        itemsCount: 3,
        category: 'peace'
      });
      if (itemsResponse.items.length) {
        setitems(itemsResponse.items);
      } else {
        console.log('[Info] No Peace item');
      }
      dispatch(setLoading(false));
    };

    if (isMountEnabled) {
      getItems();
    }

    return () => {
      setisMountEnabled(false);
      axiosCancelSource.cancel();
    };
  }, [isMountEnabled, dispatch]);

  return (
    <PeaceWrapper
      id="peace-wrapper"
      sx={{ backgroundSize: { xs: 'auto', md: 'contain' } }}
    >
      <CustomMeta
        title={`Stand for love and peace. Donate SGD 10 to Singapore Red Cross`}
        imageUrl={`https://peet.me${heroBannerMain}`}
        description={`Help rebuild the lives of those who are suffering in the conflict and receive our exclusive Peace NFT!`}
      />
      <Grid container marginBottom={'48px'}>
        <Grid
          item
          md={12}
          lg={7}
          container
          justifyContent={'flex-start'}
          alignItems={'center'}
        >
          <Typography
            component="h1"
            variant="h2"
            color="#FFF"
            gutterBottom
            sx={{ fontSize: { xs: '2.4em', md: '2.8em' } }}
          >
            Help rebuild the lives of those who are suffering in the conflict and receive
            our exclusive Peace NFT!
          </Typography>
          <Typography variant="h6" color="#FFF" paragraph>
            Stand for love and peace. Donate SGD 10 to Singapore Red Cross
          </Typography>
        </Grid>
        <Grid
          item
          container
          justifyContent={'center'}
          alignItems={'center'}
          xs={12}
          sm={12}
          md={12}
          lg={5}
        >
          <Grid
            item
            sm={12}
            md={7}
            lg={11}
            container
            justifyContent={'center'}
            alignItems={'center'}
          >
            <LazyLoadImage
              alt={''}
              src={heroBannerMain}
              style={{
                width: '100%',
                maxWidth: '340px'
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        justifyContent={'center'}
        alignItems={'center'}
        marginBottom={'48px'}
      >
        <Grid item flexGrow={1} md={12} lg={8}>
          {isLoading === false &&
            items.length !== 0 &&
            items.slice(0, 1).map((item: any, index: number) => {
              const btnClass = item.isClicked ? 'dark-adopt-button-disabled' : '';
              return (
                <LoadingButton
                  disabled
                  fullWidth
                  id={`hero_adopt_link_${item.id}`}
                  className={`hero_adopt_link_${item.id} ${btnClass} dark-adopt-button-disabled`}
                  key={`hero-button-${index}`}
                  size="large"
                  variant="contained"
                  style={{ fontSize: '28px' }}
                  loadingPosition="start"
                  loading={item.isClicked}
                  startIcon={<></>}
                  onClick={() => {
                    handlePeaceDonate(item.id);
                  }}
                >
                  {/* Click here to Donate Now */}
                  This campaign has ended.
                </LoadingButton>
              );
            })}
        </Grid>
      </Grid>

      <Grid container>
        <Grid item md={1} lg={2}></Grid>
        <Grid item md={10} lg={8} textAlign="center" marginBottom="40px">
          <Typography
            component="h2"
            variant="h2"
            color="white"
            padding="10px"
            gutterBottom
          >
            Participating Charity
          </Typography>
          <Card
            style={{
              backgroundColor: '#0f0330'
            }}
          >
            <Grid
              container
              style={{
                padding: '0px 12px 0px 12px'
              }}
              // spacing={'12px'}
            >
              <Grid
                item
                container
                justifyContent={'center'}
                alignItems={'center'}
                md={12}
                lg={4}
                style={{
                  padding: '0px',
                  margin: '18px 0px'
                }}
              >
                <img
                  src={redCrossLogo}
                  alt="Red Cross Singapore"
                  style={{
                    width: '100%',
                    maxWidth: '180px'
                  }}
                />
              </Grid>
              <Grid
                item
                md={12}
                lg={8}
                container
                justifyContent={'flex-start'}
                alignItems={'flex-start'}
                color="white"
                style={{ padding: '0px' }}
              >
                <Typography
                  variant="h6"
                  style={{
                    textAlign: 'left',
                    padding: '0px',
                    margin: '18px 12px 16px 12px',
                    fontWeight: 'bold'
                  }}
                >
                  Singapore Red Cross Supports Communities Affected by Ukraine Conflict
                </Typography>
                <Typography
                  style={{
                    textAlign: 'left',
                    padding: '0px',
                    margin: '8px 12px 18px 12px'
                  }}
                >
                  Singapore Red Cross is dedicated to relieving human suffering,
                  protecting human lives and dignity and responding to emergencies. It
                  launched a public fundraising appeal from 25 February to 31 May 2022 to
                  raise more funds to aid the expected massive humanitarian operations.
                </Typography>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>

      <Grid item xs={12} textAlign="center">
        <Typography component="h1" variant="h2" color="white" gutterBottom>
          NFT Gallery
        </Typography>
      </Grid>

      <Grid
        id="peace-list-container"
        container
        // spacing={'16px'}
        marginBottom={'48px'}
      >
        {isLoading === true &&
          [1, 2, 3].map((index: number) => {
            return (
              <Grid
                key={`skeleton-item-${index}`}
                className={classes.item}
                item
                xs={12}
                sm={12}
                md={4}
                lg={4}
                xl={4}
              >
                <Card
                  key={`random-card-skeleton`}
                  style={{
                    display: 'block',
                    margin: '0px',
                    padding: '0px 12px 12px 12px'
                  }}
                >
                  <Skeleton width={'100%'} height={'320px'} />
                  <Skeleton />
                  <Skeleton />
                  <Skeleton width={'60%'} />
                </Card>
              </Grid>
            );
          })}
        {isLoading === false &&
          items.length !== 0 &&
          items.map((item: any, index: number) => {
            return (
              <Grid
                key={`grid-item-${item.id}`}
                className={`${classes.item} card-wrapper`}
                item
                xs={12}
                sm={12}
                md={6}
                lg={4}
                xl={4}
                style={{ padding: '12px' }}
              >
                <Card
                  className="hover-zoom"
                  style={{ display: 'block', margin: '0px auto' }}
                >
                  <RouterLink
                    to={`/character/${item.id}`}
                    style={{ textDecoration: 'none', color: '#000' }}
                  >
                    <div
                      className={classes.itemImage}
                      style={{ backgroundColor: `${item.parts[1].color}30` }}
                    >
                      <LazyLoadImage
                        alt={''}
                        height={'320px'}
                        src={`${PEET_NFT_SERVER}/characters/view/${item.id}.svg`}
                        style={{ display: 'block', margin: '0px auto' }}
                      />
                    </div>
                    <CardContent
                      className="hover-bg-color"
                      style={{ paddingBottom: '10px' }}
                    >
                      <Typography
                        gutterBottom
                        variant="h5"
                        component="div"
                        style={{ fontWeight: 'bold', textTransform: 'capitalize' }}
                      >
                        {item.name}
                      </Typography>
                      <Typography
                        component="h4"
                        variant="body2"
                        style={{ textTransform: 'capitalize' }}
                      >
                        {item.masterChar.name.replace(/[_-]/g, ' ')}
                      </Typography>
                    </CardContent>
                  </RouterLink>
                  <CardContent>
                    <LoadingButton
                      disabled
                      fullWidth
                      id={`suggest_adopt_link_${item.id}`}
                      className={`suggest_adopt_link_${item.id}`}
                      loadingPosition="start"
                      variant="contained"
                      style={{ fontSize: '20px' }}
                      startIcon={
                        <img
                          src={heartIcon}
                          id={`suggest_adopt_link_icon_${item.id}`}
                          className={`suggest_adopt_link_icon_${item.id}`}
                          alt=""
                        />
                      }
                      loading={item.isClicked}
                      onClick={() => {
                        handlePeaceDonate(item.id);
                      }}
                    >
                      {/* Donate ${item.masterChar.minAmount} */}
                      This campaign has ended.
                    </LoadingButton>
                  </CardContent>
                </Card>
              </Grid>
            );
          })}
      </Grid>

      <Typography
        textAlign="center"
        component="h1"
        variant="h2"
        color="white"
        padding="10px"
        gutterBottom
      >
        Campaign Details
      </Typography>

      <Grid>
        <Card
          style={{
            display: 'block',
            backgroundColor: '#FFF',
            height: '100%',
            padding: '20px'
          }}
        >
          <Grid container spacing={5} justifyContent={'center'} alignItems={'flex-start'}>
            <Grid item lg={4}>
              <LazyLoadImage
                alt={''}
                src={peaceImage}
                style={{
                  display: 'block',
                  margin: '0px auto',
                  padding: '8px 0px',
                  height: '100%',
                  width: '100%'
                }}
              />
            </Grid>
            <Grid item lg={8}>
              <Typography variant="h4" paragraph>
                About Campaign
              </Typography>
              <p>
                Your donation will provide immediate aid for the vulnerable --
                unaccompanied minors, women with children, elderly & people with
                disabilities; support their basic needs like shelter, food/water, &
                psychosocial support.
              </p>
              <p>
                In response to the fast-growing refugee crisis, SRC has contributed $2.5
                million in humanitarian aid to Ukraine and 6 neighbouring countries -
                Poland, Hungary, Lithuania, Slovakia, Moldova and Romania.
              </p>
              <p>
                The humanitarian needs are massive & the operations are complex, with the
                ongoing conflict & border crisis hurting innocent civilians. We call for
                your support to this critical humanitarian operation.
              </p>
            </Grid>
          </Grid>
          <hr />
          <Grid container spacing={5} justifyContent={'center'} alignItems={'flex-start'}>
            <Grid item lg={4}>
              <LazyLoadImage
                alt={''}
                src={peaceSign}
                style={{
                  display: 'block',
                  margin: '0px auto',
                  height: '240px',
                  width: 'auto'
                }}
              />
            </Grid>
            <Grid item lg={8}>
              <Typography variant="h4" paragraph>
                Who is Chatrity?
              </Typography>
              <Typography variant="subtitle1" color="inherit" paragraph>
                Chatrity is a secure fundraising channel for donors to give conveniently
                to a charity organisation (eg. Singapore Red Cross) or a cause they care
                about (eg. Humanitarian) on social messaging platforms like Telegram (try
                us out at{' '}
                <a href="https://t.me/ChatrityBot" target={'_blank'} rel="noreferrer">
                  https://t.me/ChatrityBot
                </a>
                ). We help our partner charities tell their story through our smart
                chatbot interface as well as the creation and distribution of NFT-based
                digital assets. Find out more about us at{' '}
                <a href="https://hello.chatrity.com/" target={'_blank'} rel="noreferrer">
                  https://hello.chatrity.com/
                </a>
                .
              </Typography>
            </Grid>
          </Grid>
        </Card>
      </Grid>

      {isLoading === false && items.length === 0 && (
        <Typography
          component={'p'}
          variant="h5"
          color="white"
          align="center"
          style={{ padding: '0px', margin: '48px 0px' }}
        >
          No Peace Data
        </Typography>
      )}
    </PeaceWrapper>
  );
}
