import React, { useEffect, useState } from 'react';
import axios from 'axios';
import _ from 'lodash';
import { Link as RouterLink } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import FavoriteIcon from '@mui/icons-material/Favorite';
import Chip from '@mui/material/Chip';
import LoadingButton from '@mui/lab/LoadingButton';

import { PEET_NFT_SERVER } from '../../constants';
import { SosdGalleryWrapper, sosdGalleryStyles } from './SosdGallery.style';
import { getCharacters, adoptCharacter } from '../../services';
import { authSelector, setLoading } from '../../features/auth.slice';
import heartIcon from '../../images/heart.png';
import sosdBannerMain from '../../images/home-img-02.png';
import CustomMeta from '../../components/CustomMeta';

export default function SosdGallery() {
  const itemsPerPage: number = 15;

  const classes = sosdGalleryStyles();
  const dispatch = useDispatch();

  const { isLoading } = useSelector(authSelector);

  const [isMountEnabled, setisMountEnabled] = useState(true);
  const [items, setitems] = useState([]);
  const [itemCount, setitemCount] = useState<number>(0);
  const [currentPage, setcurrentPage] = useState<number>(1);
  const [pageCount, setpageCount] = useState<number>(1);

  const paginationClick = (event: React.ChangeEvent<unknown>, value: number) => {
    setcurrentPage(value);
    setisMountEnabled(true);
  };

  const sosdDonateClick = async (id: number) => {
    try {
      // if (!isAuth) {
      //   dispatch(setCurrentUrl(window.location.href));
      //   dispatch(setPeetId(id));
      //   dispatch(setOpenLoginDialog({ title: '', message: '', type: 'LOGIN' }));
      //   return;
      // }

      const newItemList = [...items];
      newItemList.forEach((i: any) => {
        if (i.id === id) {
          i.isClicked = true;
        }
      });
      setitems(newItemList);

      const adoptPayload = {
        characterId: id
      };
      const itemsResponse = await adoptCharacter(adoptPayload);
      const detailsData = itemsResponse.item;

      if (_.isEmpty(detailsData) || !detailsData.url) {
        console.error('[Error] no adopt data or token');
        return;
      }

      window.open(detailsData.url, '_self', 'noreferrer');
    } catch (error) {
      console.error('[Error] in sosdDonateClick', error);
    } finally {
      const newItemList = [...items];
      newItemList.forEach((i: any) => {
        if (i.id === id) {
          i.isClicked = false;
        }
      });
      setitems(newItemList);
    }
  };

  useEffect(() => {
    const axiosCancelSource = axios.CancelToken.source();
    const axiosCancelToken = axiosCancelSource.token;

    const getItems = async () => {
      dispatch(setLoading(true));

      const itemsResponse = await getCharacters(axiosCancelToken, {
        currentPage,
        itemsPerPage,
        status: 'all',
        category: 'sosd10'
      });

      if (itemsResponse.itemCount) {
        const pCount: number = Math.ceil(itemsResponse.itemCount / itemsPerPage);
        setitems(itemsResponse.items);
        setitemCount(itemsResponse.itemCount);
        setpageCount(pCount);
      } else {
        console.log('[Info] No Gallery item for SosdGallery');
      }
      dispatch(setLoading(false));
    };

    if (isMountEnabled) {
      getItems();
    }

    return () => {
      setisMountEnabled(false);
      axiosCancelSource.cancel();
    };
  }, [isMountEnabled, currentPage, dispatch]);

  return (
    <SosdGalleryWrapper id="hundred-gallery-wrapper">
      <CustomMeta
        title={`Let's show love to our strays`}
        imageUrl={`https://peet.me${sosdBannerMain}`}
        description={`Celebrate SOSD's 10th Year Anniversary by improving the lives of more than 500 stray dogs and receive our special Stars of SOSD NFT!`}
      />
      <Grid id="hundred-gallery-container" container spacing={'16px'}>
        <Grid item xs={12} textAlign="center">
          <Typography component="h1" variant="h2" color="white" gutterBottom>
            PEET Gallery
          </Typography>
        </Grid>

        {isLoading === false && items.length === 0 && (
          <Typography
            component={'p'}
            variant="h5"
            color="white"
            style={{ padding: '0px', margin: '48px 0px' }}
          >
            No PEET found
          </Typography>
        )}

        {isLoading === true &&
          [1, 2, 3, 4].map((i: number) => {
            return (
              <Grid
                key={`grid-item-${i}`}
                className={classes.item}
                item
                xs={12}
                sm={12}
                md={6}
                lg={4}
                xl={4}
              >
                <Card
                  style={{
                    display: 'block',
                    margin: '0px auto',
                    width: '100%',
                    height: '100%'
                  }}
                >
                  <div className={classes.itemImage}>
                    <Skeleton variant="rectangular" width={'100%'} height={'100%'} />
                  </div>
                  <CardContent>
                    <Skeleton />
                    <Skeleton width={'60%'} />
                  </CardContent>
                </Card>
              </Grid>
            );
          })}

        {isLoading === false &&
          items.length !== 0 &&
          items.map((item: any, index: number) => {
            return (
              <Grid
                key={`grid-item-${index}`}
                className={`${classes.item} card-wrapper`}
                item
                xs={12}
                sm={12}
                md={6}
                lg={4}
                xl={4}
                style={{ padding: '12px' }}
              >
                <Card
                  className="hover-zoom"
                  style={{ display: 'block', margin: '0px auto' }}
                >
                  <RouterLink
                    to={`/character/${item.id}`}
                    style={{ textDecoration: 'none', color: '#000' }}
                  >
                    <div
                      className={classes.itemImage}
                      style={{
                        backgroundColor: `${item.parts[1].color}30`,
                        position: 'relative'
                      }}
                    >
                      {item.isAvailable === false && (
                        <Chip
                          icon={
                            <FavoriteIcon
                              style={{
                                color: '#fff',
                                fontSize: '12px'
                              }}
                            />
                          }
                          label="ADOPTED"
                          style={{
                            position: 'absolute',
                            top: '21px',
                            left: '-7px',
                            transform: 'rotate(317deg)',
                            backgroundColor: '#D29912',
                            fontSize: '11px',
                            color: '#fff',
                            fontWeight: 'bold'
                          }}
                        />
                      )}
                      <LazyLoadImage
                        alt={''}
                        height={'200px'}
                        src={`${PEET_NFT_SERVER}/characters/view/${item.id}.svg`}
                        style={{ display: 'block', margin: '0px auto' }}
                      />
                    </div>

                    <CardContent className="hover-bg-color">
                      <Typography
                        gutterBottom
                        variant="h5"
                        component="div"
                        style={{ textTransform: 'capitalize' }}
                      >
                        {item.name}
                      </Typography>
                      <Typography
                        component="h4"
                        variant="body2"
                        style={{ textTransform: 'capitalize', marginBottom: '20px' }}
                      >
                        {item.masterChar.name.replace(/[_-]/g, ' ')}
                      </Typography>
                    </CardContent>
                  </RouterLink>
                  <CardContent>
                    <LoadingButton
                      fullWidth
                      id={`adopt_link_${item.id}`}
                      className={`adopt_link ${item.id}`}
                      loadingPosition="start"
                      variant="contained"
                      startIcon={
                        <img
                          src={heartIcon}
                          id={`adopt_link_icon_${item.id}`}
                          className={`adopt_link_icon`}
                          alt=""
                        />
                      }
                      loading={item.isClicked}
                      // disabled={item.isAvailable ? false : true}
                      disabled
                      onClick={() => {
                        sosdDonateClick(item.id);
                      }}
                    >
                      {/* Donate ${item.masterChar.minAmount} to Adopt */}
                      This campaign has ended.
                    </LoadingButton>
                  </CardContent>
                </Card>
              </Grid>
            );
          })}
      </Grid>
      {isLoading === false && itemCount > itemsPerPage && (
        <Grid
          container
          justifyContent="flex-end"
          alignContent={'center'}
          style={{ margin: '24px 0px 0px 0px' }}
        >
          <Grid item style={{ color: '#fff', lineHeight: '32px' }}>
            Total {itemCount}
          </Grid>
          <Pagination
            showFirstButton
            showLastButton
            count={pageCount}
            page={currentPage}
            onChange={paginationClick}
            color="secondary"
            renderItem={(item) => (
              <PaginationItem
                style={{
                  color: '#fff',
                  backgroundColor: item.selected ? '#df00fc' : undefined
                }}
                {...item}
              />
            )}
          />
        </Grid>
      )}
    </SosdGalleryWrapper>
  );
}
