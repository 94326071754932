import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import axios from 'axios';
import _ from 'lodash';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useSelector, useDispatch } from 'react-redux';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { SosdWrapper, sosdStyles } from './Sosd.style';
import { getSOSDItems, adoptCharacter } from '../../services';
import sosdBannerMain from '../../images/home-img-02.png';
import sosdTenYear from '../../images/sosd-10th-year.png';
import { PEET_NFT_SERVER } from '../../constants';
import heartIcon from '../../images/heart.png';
import CustomMeta from '../../components/CustomMeta';
import {
  authSelector,
  // setPeetId,
  // setCurrentUrl,
  setLoading
} from '../../features/auth.slice';
// import { setOpenLoginDialog } from '../../features/dialogBox.slice';

export default function Sosd() {
  const classes = sosdStyles();
  const dispatch = useDispatch();

  const {
    // isAuth,
    isLoading
  } = useSelector(authSelector);

  const [isMountEnabled, setisMountEnabled] = useState(true);
  const [items, setitems] = useState([]);

  const handleDonateClick = async (id: number) => {
    try {
      // if (!isAuth) {
      //   dispatch(setCurrentUrl(window.location.href));
      //   dispatch(setPeetId(id));
      //   dispatch(setOpenLoginDialog({ title: '', message: '', type: 'LOGIN' }));
      //   return;
      // }

      const newItemList = [...items];
      newItemList.forEach((i: any) => {
        if (i.id === id) {
          i.isClicked = true;
        }
      });
      setitems(newItemList);

      const adoptPayload = {
        characterId: id
      };
      const itemsResponse = await adoptCharacter(adoptPayload);
      const detailsData = itemsResponse.item;

      if (_.isEmpty(detailsData) || !detailsData.url) {
        console.error('[Error] no adopt data or token');
        return;
      }

      window.open(detailsData.url, '_self', 'noreferrer');
    } catch (error) {
      console.error('[Error] in handleDonateClick', error);
    } finally {
      const newItemList = [...items];
      newItemList.forEach((i: any) => {
        if (i.id === id) {
          i.isClicked = false;
        }
      });
      setitems(newItemList);
    }
  };

  useEffect(() => {
    const axiosCancelSource = axios.CancelToken.source();
    const axiosCancelToken = axiosCancelSource.token;

    const getItems = async () => {
      dispatch(setLoading(true));
      const itemsResponse = await getSOSDItems(axiosCancelToken, {
        status: 'available',
        itemsCount: 3,
        category: 'sosd10'
      });
      if (itemsResponse.items.length) {
        setitems(itemsResponse.items);
      } else {
        console.log('[Info] No SoSD item');
      }
      dispatch(setLoading(false));
    };

    if (isMountEnabled) {
      getItems();
    }

    return () => {
      setisMountEnabled(false);
      axiosCancelSource.cancel();
    };
  }, [isMountEnabled, dispatch]);

  return (
    <SosdWrapper id="sosd-wrapper" sx={{ backgroundSize: { xs: 'auto', md: 'contain' } }}>
      <CustomMeta
        title={`Let's show love to our strays`}
        imageUrl={`https://peet.me${sosdBannerMain}`}
        description={`Celebrate SOSD's 10th Year Anniversary by improving the lives of more than 500 stray dogs and receive our special Stars of SOSD NFT!`}
      />

      <Grid container marginBottom={'48px'}>
        <Grid
          item
          md={12}
          lg={7}
          container
          justifyContent={'flex-start'}
          alignItems={'center'}
        >
          <Typography
            component="h1"
            variant="h2"
            color="#FFF"
            gutterBottom
            sx={{ fontSize: { xs: '2.0em', md: '2.3em' } }}
          >
            Celebrate SOSD's 10th Year Anniversary by improving the lives of more than 500
            stray dogs and receive our special Stars of SOSD NFT!
          </Typography>

          <Typography variant="h6" paragraph sx={{ color: { xs: '#FFF' } }}>
            Let's show love to our strays.
          </Typography>
        </Grid>

        <Grid
          item
          container
          justifyContent={'center'}
          alignItems={'center'}
          xs={12}
          sm={12}
          md={12}
          lg={5}
        >
          <Grid
            item
            sm={12}
            md={7}
            lg={11}
            container
            justifyContent={'center'}
            alignItems={'center'}
          >
            <LazyLoadImage
              alt={''}
              src={sosdBannerMain}
              style={{
                width: '100%',
                maxWidth: '340px'
              }}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid
        component={Paper}
        container
        flexDirection={'column'}
        justifyContent={'center'}
        alignItems={'center'}
        marginBottom={'48px'}
        style={{ padding: '32px 16px', backgroundColor: '#FFF5DD' }}
      >
        <Grid item md={12} lg={10}>
          <Typography style={{ margin: '8px 0px 12px 0px', color: '#000' }}>
            Inspired by Benji, Trooper, Sherlock, Marsh and Cooper (
            <Link
              href="http://sosd.org.sg/adopt-a-dog/"
              target={'_blank'}
              color="inherit"
            >
              SOSD Dog Adoption Singapore | How to Adopt a Dog
            </Link>
            ), our NFT collection is available in 3 unique backdrops:
          </Typography>
        </Grid>

        <Grid item md={12} lg={10} style={{ width: '100%' }}>
          <TableContainer
            component={Paper}
            style={{
              maxHeight: '320px',
              margin: '8px 0px 16px 0px',
              backgroundColor: '#A1683D',
              color: '#FFF'
            }}
          >
            <Table
              stickyHeader
              aria-label="nft-collection-table"
              style={{ color: '#FFF' }}
            >
              <TableHead>
                <TableRow>
                  <TableCell style={{ backgroundColor: '#A1683D', color: '#FFF' }}>
                    NFT Collection
                  </TableCell>
                  <TableCell style={{ backgroundColor: '#A1683D', color: '#FFF' }}>
                    Description
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ backgroundColor: '#A1683D', color: '#FFF' }}
                  >
                    Availability
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ backgroundColor: '#A1683D', color: '#FFF' }}
                  >
                    Minimum Donation
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow
                  key={'collection-row-1'}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell scope="row" style={{ color: '#FFF' }}>
                    SOSD Gold
                  </TableCell>
                  <TableCell scope="row" style={{ color: '#FFF' }}>
                    Plain Background
                  </TableCell>
                  <TableCell scope="row" align="center" style={{ color: '#FFF' }}>
                    100
                  </TableCell>
                  <TableCell scope="row" align="center" style={{ color: '#FFF' }}>
                    $10
                  </TableCell>
                </TableRow>
                <TableRow
                  key={'collection-row-2'}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell scope="row" style={{ color: '#FFF' }}>
                    SOSD Sungei
                  </TableCell>
                  <TableCell scope="row" style={{ color: '#FFF' }}>
                    Sungei Tengah Shelter
                  </TableCell>
                  <TableCell scope="row" align="center" style={{ color: '#FFF' }}>
                    50
                  </TableCell>
                  <TableCell scope="row" align="center" style={{ color: '#FFF' }}>
                    $20
                  </TableCell>
                </TableRow>
                <TableRow
                  key={'collection-row-3'}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell scope="row" style={{ color: '#FFF' }}>
                    SOSD Jurong
                  </TableCell>
                  <TableCell scope="row" style={{ color: '#FFF' }}>
                    Jurong Island Shelter
                  </TableCell>
                  <TableCell scope="row" align="center" style={{ color: '#FFF' }}>
                    50
                  </TableCell>
                  <TableCell scope="row" align="center" style={{ color: '#FFF' }}>
                    $50
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

        <Grid item sm={12} md={10} lg={10} container spacing={'12px'}>
          <Grid item sm={12} md={12} lg={6}>
            {isLoading === false &&
              items.length !== 0 &&
              items.slice(0, 1).map((item: any, index: number) => {
                const btnClass = item.isClicked ? 'dark-adopt-button-disabled' : '';
                return (
                  <LoadingButton
                    disabled
                    fullWidth
                    id={`hero_adopt_link_${item.id}`}
                    className={`hero_adopt_link_${item.id} ${btnClass}`}
                    key={`hero-button-${index}`}
                    size="large"
                    variant="contained"
                    style={{ fontSize: '28px' }}
                    loadingPosition="start"
                    loading={item.isClicked}
                    startIcon={<></>}
                    onClick={() => {
                      handleDonateClick(item.id);
                    }}
                  >
                    {/* Click Here To Donate Now */}
                    This campaign has ended.
                  </LoadingButton>
                );
              })}
          </Grid>
          <Grid item sm={12} md={12} lg={6}>
            <Button
              fullWidth
              href="/sosdbig10/gallery"
              variant="outlined"
              style={{
                fontSize: '28px',
                color: '#1665C0',
                borderColor: '#1665C0'
              }}
            >
              View Full Collection
            </Button>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} textAlign="center">
        <Typography component="h1" variant="h2" color="white" gutterBottom>
          NFT Gallery
        </Typography>
      </Grid>

      <Grid
        container
        style={{
          margin: '0px 0px 12px 0px'
        }}
      >
        {isLoading === false &&
          items.length !== 0 &&
          items.map((item: any, index: number) => {
            return (
              <Grid
                key={`grid-item-${item.id}`}
                className={`${classes.item} card-wrapper`}
                item
                xs={12}
                sm={12}
                md={6}
                lg={4}
                xl={4}
                style={{ padding: '12px' }}
              >
                <Card
                  className="hover-zoom"
                  style={{ display: 'block', margin: '0px auto' }}
                >
                  <RouterLink
                    to={`/character/${item.id}`}
                    style={{ textDecoration: 'none', color: '#000' }}
                  >
                    <div
                      className={classes.itemImage}
                      style={{ backgroundColor: `${item.parts[1].color}30` }}
                    >
                      <LazyLoadImage
                        alt={''}
                        height={'320px'}
                        src={`${PEET_NFT_SERVER}/characters/view/${item.id}.svg`}
                        style={{ display: 'block', margin: '0px auto' }}
                      />
                    </div>
                    <CardContent className="hover-bg-color">
                      <Typography
                        gutterBottom
                        variant="h5"
                        component="div"
                        style={{ textTransform: 'capitalize' }}
                      >
                        {item.name}
                      </Typography>
                      <Typography
                        component="h4"
                        variant="body2"
                        style={{ textTransform: 'capitalize', marginBottom: '20px' }}
                      >
                        {item.masterChar.name.replace(/[_-]/g, ' ')}
                      </Typography>
                    </CardContent>
                  </RouterLink>
                  <CardContent>
                    <LoadingButton
                      disabled
                      fullWidth
                      id={`suggest_adopt_link_${item.id}`}
                      className={`suggest_adopt_link_${item.id}`}
                      loadingPosition="start"
                      variant="contained"
                      style={{ fontSize: '20px' }}
                      startIcon={
                        <img
                          src={heartIcon}
                          id={`suggest_adopt_link_icon_${item.id}`}
                          className={`suggest_adopt_link_icon_${item.id}`}
                          alt=""
                        />
                      }
                      loading={item.isClicked}
                      onClick={() => {
                        handleDonateClick(item.id);
                      }}
                    >
                      {/* Donate ${item.masterChar.minAmount} */}
                      This campaign has ended.
                    </LoadingButton>
                  </CardContent>
                </Card>
              </Grid>
            );
          })}

        {isLoading === true &&
          [1, 2, 3].map((index: number) => {
            return (
              <Grid
                key={`skeleton-item-${index}`}
                className={classes.item}
                item
                xs={12}
                sm={12}
                md={4}
                lg={4}
                xl={4}
                style={{
                  padding: '20px'
                }}
              >
                <Card
                  key={`random-card-skeleton`}
                  style={{
                    display: 'block',
                    margin: '0px',
                    padding: '0px 12px 12px 12px'
                  }}
                >
                  <Skeleton width={'100%'} height={'200px'} />
                  <Skeleton />
                  <Skeleton />
                  <Skeleton width={'60%'} />
                </Card>
              </Grid>
            );
          })}

        {isLoading === false && items.length === 0 && (
          <Typography
            component={'p'}
            variant="h5"
            color="white"
            align="center"
            style={{ padding: '0px', margin: '48px 0px' }}
          >
            No SoSD Data
          </Typography>
        )}
      </Grid>

      <Button
        href="/sosdbig10/gallery"
        size="large"
        variant="contained"
        color="info"
        style={{ fontSize: '28px', margin: '12px 0px 48px 0px' }}
      >
        View more SOSD NFTs
      </Button>

      <Grid>
        <Card
          style={{
            display: 'block',
            backgroundColor: '#FFFBE6',
            height: '100%',
            padding: '20px'
          }}
        >
          <Grid container spacing={1}>
            <Grid item lg={6} style={{ paddingRight: '20px', textAlign: 'justify' }}>
              <h2 style={{ marginTop: '0px' }}>About Campaign</h2>
              <Typography
                variant="subtitle1"
                textAlign={'left'}
                color="inherit"
                paragraph
              >
                Happy Birthday SOSD! To celebrate this milestone, SOSD and Chatrity have
                partnered to gift a free Stars of SOSD NFT upon donation. It has been an
                arduous and fulfilling journey for SOSD and we know that more can be done
                for our stray and rescued fur friends.
              </Typography>
              <Typography
                variant="subtitle1"
                textAlign={'left'}
                color="inherit"
                paragraph
              >
                Your donations will help fund rescue, rehabilitation, and re-homing
                operations. In addition, support the SOSD shelter at Sungei Tengah and
                Jurong Island, Trap-Neuter-Rehome/Release-program, Dog Foster program and
                Education and Outreach efforts, as well as administration and operations
                costs. Your generosity will provide a better life for more than 500 dogs
                under SOSD's care.
              </Typography>
              <hr />
              <h2>Who is Chatrity?</h2>
              <Typography
                variant="subtitle1"
                textAlign={'left'}
                color="inherit"
                paragraph
              >
                Chatrity is a secure fundraising channel for donors to give conveniently
                to a charity organisation (eg. SOSD) or a cause they care about (eg.
                Animal Welfare) on social messaging platforms like Telegram (try us out at{' '}
                <a href="https://t.me/ChatrityBot" target={'_blank'} rel="noreferrer">
                  https://t.me/ChatrityBot
                </a>
                ). We help our partner charities tell their story through our smart
                chatbot interface as well as the creation and distribution of NFT-based
                digital assets. Find out more about us at{' '}
                <a href="https://hello.chatrity.com/" target={'_blank'} rel="noreferrer">
                  https://hello.chatrity.com/
                </a>
                .
              </Typography>
            </Grid>
            <Grid
              item
              container
              justifyContent={'center'}
              alignItems={'flex-start'}
              lg={6}
            >
              <img src={sosdTenYear} style={{ width: '100%' }} alt="" />
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </SosdWrapper>
  );
}
