import axios from 'axios';

import { IResponse } from '../interfaces';
import { PEET_NFT_SERVER } from '../constants';

export const getTrybeItems = async (cancelToken: any, payload: any): Promise<any> => {
  const resData: IResponse = {
    isSucceeded: false,
    message: '',
    error: {},
    items: [],
    itemCount: 0
  };

  try {
    const response = await axios.post(`${PEET_NFT_SERVER}/api/peet/random`, payload, {
      headers: {
        'Content-Type': 'application/json'
      },
      cancelToken: cancelToken
    });
    resData.isSucceeded = true;
    resData.items = response.data.items;
    resData.itemCount = response.data.itemCount;

    return resData;
  } catch (error) {
    if (axios.isCancel(error)) {
      console.log('[Info]: Trybe items API aborted successfully!');
    } else {
      console.error('[Error]: getTrybeItems', error);
    }
    resData.error = error;
    return resData;
  }
};

export const getSOSDItems = async (cancelToken: any, payload: any): Promise<any> => {
  const resData: IResponse = {
    isSucceeded: false,
    message: '',
    error: {},
    items: [],
    itemCount: 0
  };

  try {
    const response = await axios.post(`${PEET_NFT_SERVER}/api/peet/random`, payload, {
      headers: {
        'Content-Type': 'application/json'
      },
      cancelToken: cancelToken
    });
    resData.isSucceeded = true;
    resData.items = response.data.items;
    resData.itemCount = response.data.itemCount;

    return resData;
  } catch (error) {
    if (axios.isCancel(error)) {
      console.log('[Info]: SoSD items API aborted successfully!');
    } else {
      console.error('[Error]: getSOSDItems', error);
    }
    resData.error = error;
    return resData;
  }
};

export const getPeaceItems = async (cancelToken: any, payload: any): Promise<any> => {
  const resData: IResponse = {
    isSucceeded: false,
    message: '',
    error: {},
    items: [],
    itemCount: 0
  };

  try {
    const response = await axios.post(`${PEET_NFT_SERVER}/api/peet/random`, payload, {
      headers: {
        'Content-Type': 'application/json'
      },
      cancelToken: cancelToken
    });
    resData.isSucceeded = true;
    resData.items = response.data.items;
    resData.itemCount = response.data.itemCount;

    return resData;
  } catch (error) {
    if (axios.isCancel(error)) {
      console.log('[Info]: Peace Characters API aborted successfully!');
    } else {
      console.error('[Error]: getPeaceItems', error);
    }
    resData.error = error;
    return resData;
  }
};

export const getCharacters = async (cancelToken: any, payload: any): Promise<any> => {
  const resData: IResponse = {
    isSucceeded: false,
    message: '',
    error: {},
    items: [],
    itemCount: 0
  };

  try {
    const response = await axios.post(`${PEET_NFT_SERVER}/api/peet`, payload, {
      headers: {
        'Content-Type': 'application/json'
      }
      // cancelToken: cancelToken
    });
    resData.isSucceeded = true;
    resData.items = response.data.items;
    resData.itemCount = response.data.itemCount;

    return resData;
  } catch (error) {
    if (axios.isCancel(error)) {
      console.log('[Info]: Characters API aborted successfully!');
    } else {
      console.error('[Error]: getCharacters', error);
    }
    resData.error = error;
    return resData;
  }
};

export const getRandomCharacters = async (
  cancelToken: any,
  payload: any
): Promise<any> => {
  const resData: IResponse = {
    isSucceeded: false,
    message: '',
    error: {},
    items: []
  };

  try {
    const response = await axios.post(`${PEET_NFT_SERVER}/api/peet/random`, payload, {
      headers: {
        'Content-Type': 'application/json'
      }
      // cancelToken: cancelToken
    });
    resData.isSucceeded = true;
    const itemData = response.data.items;
    itemData.forEach((element: any) => {
      element.isClicked = false;
    });
    resData.items = itemData;

    return resData;
  } catch (error) {
    if (axios.isCancel(error)) {
      console.log('[Info]: Characters API aborted successfully!');
    } else {
      console.error('[Error]: getCharacters', error);
    }
    resData.error = error;
    return resData;
  }
};
