import axios from 'axios';
import { ethers } from 'ethers';
import _ from 'lodash';

import { PEET_NFT_SERVER } from '../constants';
import { IResponse } from '../interfaces';

declare global {
  interface Window {
    ethereum: any;
  }
}

export const verifyUserByToken = async (verifyToken: any): Promise<any> => {
  const resData: IResponse = {
    isSucceeded: false,
    message: '',
    error: {},
    item: {}
  };

  try {
    const response = await axios.get(
      `${PEET_NFT_SERVER}/user/register/email/verify?token=${verifyToken}`,
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    );
    const res = response.data;

    if (!res.user) {
      resData.message = 'No user found. Please try again.';
      return resData;
    }

    resData.isSucceeded = true;
    resData.message = 'Your email has been verified successfully.';
    resData.item = res.user;

    return resData;
  } catch (error: any) {
    console.error('[Error]: verifyUserByToken', JSON.stringify(error));
    if (error.response) {
      if (error.response.status === 500) {
        resData.message = error.response.data;
      } else {
        switch (error.response.data) {
          case 'TOKEN_EXPIRED':
            resData.message = 'Token expired.';
            break;
          case 'EMAIL_ALREADY_USED':
            resData.message = 'Email is already existed.';
            break;
          default:
            resData.message = 'Something went wrong. Please try again.';
            break;
        }
      }
    } else {
      resData.message = 'Something went wrong. Please try again.';
      resData.error = error;
    }
    return resData;
  }
};

export const signUpEmail = async (payload: any): Promise<any> => {
  const resData: IResponse = {
    isSucceeded: false,
    message: '',
    error: {},
    item: {}
  };

  try {
    const refCode = localStorage.getItem('refCode') || '';
    if (refCode !== '') payload.refCode = localStorage.getItem('refCode');
    const response = await axios.post(`${PEET_NFT_SERVER}/user/register/email`, payload, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
    const res = response.data;

    if (res.status !== 'SUCCEEDED') {
      resData.message = 'Something went wrong. Please try again.';
      return resData;
    }

    resData.isSucceeded = true;
    resData.item = res;
    localStorage.removeItem('refCode');

    return resData;
  } catch (error: any) {
    console.error('[Error]: signUpEmail', JSON.stringify(error));
    if (error.response) {
      if (error.response.status === 500) {
        resData.message = error.response.data;
      } else {
        switch (error.response.data) {
          case 'EMAIL_ALREADY_USED':
            resData.message = 'Email is already existed.';
            break;
          default:
            resData.message = 'Something went wrong. Please try again.';
            break;
        }
      }
    } else {
      resData.message = 'Something went wrong. Please try again.';
      resData.error = error;
    }
    return resData;
  }
};

export const verifyOTP = async (payload: any): Promise<any> => {
  const resData: IResponse = {
    isSucceeded: false,
    message: '',
    error: {},
    item: {}
  };

  try {
    const response = await axios.post(`${PEET_NFT_SERVER}/otp/verify`, payload, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
    const res = response.data;
    if (res.status !== 'Success!') {
      resData.message = 'Verification Failed. Please try again.';
      return resData;
    }

    resData.isSucceeded = true;
    resData.item = res;

    return resData;
  } catch (error: any) {
    console.error('[Error]: verifyOTP', JSON.stringify(error));
    resData.message = error.message;
    resData.error = error;
    return resData;
  }
};

export const resendOTP = async (payload: any): Promise<any> => {
  const resData: IResponse = {
    isSucceeded: false,
    message: '',
    error: {},
    item: {}
  };

  try {
    const response = await axios.post(`${PEET_NFT_SERVER}/otp/resend`, payload, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
    const res = response.data;

    if (!res || _.isEmpty(res.req_id)) {
      resData.message = 'No req_id.';
      return resData;
    }

    resData.isSucceeded = true;
    resData.item = { requestId: res.req_id };

    return resData;
  } catch (error: any) {
    console.error('[Error]: resendOTP', JSON.stringify(error));
    resData.message = error.message;
    resData.error = error;
    return resData;
  }
};

export const generateOTP = async (payload: any): Promise<any> => {
  const resData: IResponse = {
    isSucceeded: false,
    message: '',
    error: {},
    item: {}
  };

  try {
    const response = await axios.post(`${PEET_NFT_SERVER}/otp/generate`, payload, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
    const res = response.data;

    if (!res || _.isEmpty(res.req_id)) {
      resData.message = 'No req_id.';
      return resData;
    }

    resData.isSucceeded = true;
    resData.item = { requestId: res.req_id };

    return resData;
  } catch (error: any) {
    console.error('[Error]: generateOTP', JSON.stringify(error));
    if (error.response) {
      if (error.response.status === 500) {
        resData.message = error.response.data;
      } else {
        switch (error.response.data) {
          case 'USER_NOT_FOUND':
            resData.message = 'Invalid Phone Number';
            break;
          default:
            resData.message = 'Something went wrong. Please try again.';
            break;
        }
      }
    } else {
      resData.message = 'Something went wrong. Please try again.';
      resData.error = error;
    }
    return resData;
  }
};

export const getUser = async (): Promise<any> => {
  const resData: IResponse = {
    isSucceeded: false,
    message: '',
    error: {},
    item: {}
  };

  try {
    const token = localStorage.getItem('token') || '';
    const res = await axios.get(`${PEET_NFT_SERVER}/user`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    });

    const userData = res.data.user;

    if (!userData) {
      resData.message = 'User not found or Invalid user session.';
      return resData;
    }

    resData.isSucceeded = true;
    resData.item = userData;

    return resData;
  } catch (error: any) {
    console.error('[Error]: login', JSON.stringify(error));
    resData.message = error.message;
    resData.error = error;
    return resData;
  }
};

export const login = async (payload: any): Promise<any> => {
  const resData: IResponse = {
    isSucceeded: false,
    message: '',
    error: {},
    item: {}
  };

  try {
    const response = await axios.post(`${PEET_NFT_SERVER}/user/login`, payload, {
      headers: {
        'Content-Type': 'application/json'
      }
    });

    const loginUser = response.data.user;

    if (!loginUser) {
      resData.message = 'No User Data.';
      return resData;
    }

    localStorage.setItem('token', loginUser.token);
    resData.isSucceeded = true;
    resData.item = loginUser;

    return resData;
  } catch (error: any) {
    console.error('[Error]: login', JSON.stringify(error));
    if (error.response) {
      if (error.response.status === 500) {
        resData.message = error.response.data;
      } else {
        switch (error.response.data) {
          case 'INVALID_EMAIL':
            resData.message = 'Invalid email or email not found.';
            break;
          case 'INVALID_PASSWORD':
            resData.message = 'Incorrect password.';
            break;
          default:
            resData.message = 'Something went wrong. Please try again.';
            break;
        }
      }
    } else {
      resData.message = 'Something went wrong. Please try again.';
      resData.error = error;
    }
    return resData;
  }
};

// NOTE: connect ether and login to Server
export const signAndLogin = async () => {
  const resData: IResponse = {
    isSucceeded: false,
    message: '',
    item: {},
    error: {}
  };

  try {
    if (!window.ethereum) {
      resData.message = 'No crypto wallet found. Please install it.';
      return resData;
    }

    const message = 'SIGN_MESSAGE_AND_LOGIN';
    await window.ethereum.send('eth_requestAccounts');
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const signature = await signer.signMessage(message);
    const address = await signer.getAddress();

    if (!signature || !address) {
      resData.message = 'Failed to connect crypto wallet.';
      return resData;
    }

    const loginRes: any = await login({
      message,
      signature,
      address
    });

    if (!loginRes.isSucceeded) {
      resData.message = loginRes.message;
      return resData;
    }

    localStorage.setItem('token', loginRes.item.token);
    resData.isSucceeded = true;
    resData.message = 'Wallet connected successfully.';
    resData.item = loginRes.item;
    return resData;
  } catch (error: any) {
    console.error('error', JSON.stringify(error));
    resData.message = error.message;
    return resData;
  }
};

// NOTE: connect ether
export const signMessage = async () => {
  const resData: IResponse = {
    isSucceeded: false,
    message: '',
    item: {},
    error: {}
  };

  try {
    if (!window.ethereum) {
      resData.message = 'No crypto wallet found. Please install it.';
      return resData;
    }

    const message = 'SIGN_MESSAGE_AND_LOGIN';
    await window.ethereum.send('eth_requestAccounts');
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const signature = await signer.signMessage(message);
    const address = await signer.getAddress();

    if (!signature || !address) {
      resData.message = 'Failed to connect crypto wallet.';
      return resData;
    }

    resData.isSucceeded = true;
    resData.item = {
      message,
      signature,
      address
    };

    return resData;
  } catch (error: any) {
    console.error('error', JSON.stringify(error));
    resData.message = error.message;
    return resData;
  }
};
