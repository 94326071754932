// import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';

export const HomeWrapper = styled('div')(({ theme }) => ({
  padding: '80px 5%',
  margin: '0px',
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  boxSizing: 'border-box',
  background: 'linear-gradient(0deg, rgba(30,4,37,1) 0%, rgba(145,22,84,1) 48%)'
}));
