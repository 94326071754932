import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';
import trybeBackground from '../../images/bg-trybe.jpg';

export const TrybeWrapper = styled('div')(({ theme }) => ({
  padding: '80px 5%',
  margin: '0px',
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  boxSizing: 'border-box',
  backgroundImage: `url(${trybeBackground})`,
  backgroundColor: '#182406',
  backgroundPosition: 'top center',
  backgroundRepeat: 'no-repeat'
}));

export const trybeStyles = makeStyles({
  item: {
    width: '100%',
    height: 'auto',
    margin: '0px',
    padding: '0px',
    color: 'black'
  },
  itemImage: {
    width: '100%'
  },
  overlay: {
    bottom: '0',
    left: '0',
    opacity: '.5',
    position: 'absolute',
    right: '0',
    top: '0',
    zIndex: '0'
  }
});
