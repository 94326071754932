import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';

export const FooterContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flexWrap: 'nowrap',
  alignContent: 'center',
  justifyContent: 'center',
  alignItems: 'stretch',
  width: '100%',
  [theme.breakpoints.down('xl')]: {
    maxWidth: '960px'
  },
  [theme.breakpoints.up('xl')]: {
    maxWidth: '1152px'
  }
}));

export const footerStyles = makeStyles({
  wrapper: {
    padding: '100px 40px',
    background: '#0f0330'
  },
  gridTitleItem: {
    padding: '0px',
    margin: '0px',
    textDecoration: 'none',
    color: '#fff'
  },
  gridMenuItem: {
    textAlign: 'center',
    textDecoration: 'none',
    color: '#fff'
  }
});
