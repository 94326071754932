import React from 'react';
import { useParams } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import {
  FacebookShareButton,
  // LinkedinShareButton,
  TelegramShareButton,
  // TwitterShareButton,
  WhatsappShareButton,
  FacebookIcon,
  // LinkedinIcon,
  TelegramIcon,
  // TwitterIcon,
  WhatsappIcon
} from 'react-share';

import { detailsStyles } from './CharacterDetails.style';
import { PEET_NFT_SERVER } from '../../constants';

export default function CharacterShare() {
  const classes = detailsStyles();

  let { id } = useParams();

  return (
    <div className={classes.shareContainer}>
      <Typography variant="h6">Share:</Typography>
      <FacebookShareButton
        url={`${PEET_NFT_SERVER}/characters/social-share/${id}`}
        quote={'100 Strays NFT'}
        hashtag={'#100StraysNFT'}
        className={classes.shareButton}
      >
        <FacebookIcon size={32} round />
      </FacebookShareButton>
      {/*       
      <LinkedinShareButton
        url={`${PEET_NFT_SERVER}/characters/social-share/${id}`}
        // title={'100 Strays NFT'}
        // summary={'description'}
        className={classes.shareButton}
      >
        <LinkedinIcon size={32} round />
      </LinkedinShareButton> 
      */}
      <TelegramShareButton
        url={`${PEET_NFT_SERVER}/characters/social-share/${id}`}
        title={'100 Strays NFT'}
        className={classes.shareButton}
      >
        <TelegramIcon size={32} round />
      </TelegramShareButton>
      {/* 
      <TwitterShareButton
        url={`${PEET_NFT_SERVER}/characters/social-share/${id}`}
        title={'100 Strays NFT'}
        // hashtags={['100StraysNFT', 'PEET_NFT']}
        className={classes.shareButton}
      >
        <TwitterIcon size={32} round />
      </TwitterShareButton> 
      */}
      <WhatsappShareButton
        url={`${PEET_NFT_SERVER}/characters/social-share/${id}`}
        title={'100 Strays NFT'}
        className={classes.shareButton}
      >
        <WhatsappIcon size={32} round />
      </WhatsappShareButton>
    </div>
  );
}
