import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import { ThemeProvider } from '@mui/material/styles';

import StaticHeader from '../../partials/Header/StaticHeader';
import ScrollHeader from '../../partials/Header/ScrollHeader';
import Footer from '../../partials/Footer';
import theme from '../../theme';
import { mainLayoutStyles } from './MainLayout.style';
import CustomDialogBox from '../../components/CustomDialogBox';
import CustomMeta from '../../components/CustomMeta';

export default function MainLayout() {
  const classes = mainLayoutStyles();
  let search = useLocation().search;

  let refCode = new URLSearchParams(search).get('refCode');
  if (refCode) localStorage.setItem('refCode', refCode);

  return (
    <ThemeProvider theme={theme}>
      <CustomMeta
        title={'PEET Chatrity Fundraising'}
        imageUrl={
          'https://peet.me/static/media/hero-banner-main.e55ef6795914cf2a56b9.png'
        }
        description={
          "PEETs (Personality Embedded Evolving Tokens) are a group of digital strays in Singapore looking for a socially conscious community to adopt, care and advocate for them. 100% of proceeds will go to Chatrity fundraising platform's partner animal charities - SPCA, SOSD and ACRES to fund emergency rescues, rehabilitation, medical expenses and more."
        }
      />

      <CssBaseline />
      <Grid
        id="main-grid"
        className={classes.mainGrid}
        container
        direction="column"
        flexWrap={'nowrap'}
        justifyContent={'flex-start'}
        alignItems={'center'}
      >
        <ScrollHeader />
        <StaticHeader />
        <div id="content-wrapper" className={classes.contentWrapper}>
          <Outlet />
        </div>
        <Footer />
      </Grid>
      <CustomDialogBox />
    </ThemeProvider>
  );
}
