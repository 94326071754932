import React from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton';
import { detailsStyles } from './CharacterDetails.style';

export default function CharacterSkeleton() {
  const classes = detailsStyles();
  return (
    <Grid
      container
      component={Paper}
      id="paper-container"
      direction={'column'}
      className={classes.paperContainer}
    >
      <Grid id="image-grid-container" container>
        <Skeleton variant="rectangular" width={'100%'} height={'400px'} />
      </Grid>
      <Grid
        id="image-content-container"
        maxWidth={'1024px'}
        container
        direction={'row'}
        style={{ padding: '24px 16px 16px 16px' }}
      >
        <Skeleton width={'100%'} height={'32px'} />
        <Skeleton width={'100%'} height={'32px'} />
        <Skeleton width="60%" height={'32px'} />
      </Grid>
    </Grid>
  );
}
