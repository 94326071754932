import React from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { Typography } from '@mui/material';

export default function CompleteSignUp() {
  return (
    <Grid
      container
      component={Paper}
      alignItems={'center'}
      justifyContent={'center'}
      // style={{ height: '100vh' }}
    >
      <Paper
        variant="outlined"
        style={{
          margin: '20px',
          padding: '0px',
          minWidth: '300px',
          minHeight: '300px',
          display: 'flex',
          flexDirection: 'column',
          flexWrap: 'nowrap',
          alignContent: 'center',
          justifyContent: 'flex-start',
          alignItems: 'stretch'
        }}
      >
        <h1 style={{ textAlign: 'center' }}>Submitted</h1>
        <Grid
          id="signup-grid-container"
          container
          style={{
            margin: '0px',
            padding: '12px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'stretch'
          }}
        >
          <Typography align="center" style={{ margin: '12px 0px' }}>
            We have sent the Verification Link.
            <br />
            Please check your email and verify.
          </Typography>
        </Grid>
      </Paper>
    </Grid>
  );
}
