import React from 'react';
import Hamburger from 'hamburger-react';

interface IHamburgerButtonProps {
  isMobileMenuOpen: boolean;
  setisMobileMenuOpen: Function;
}

const HamburgerButton = (props: IHamburgerButtonProps) => {
  const { isMobileMenuOpen, setisMobileMenuOpen } = props;

  const toggleBurger = () => {
    let togBurger: boolean = isMobileMenuOpen ? false : true;
    setisMobileMenuOpen(togBurger);
  };

  return (
    <Hamburger
      direction="right"
      color="#fff"
      distance="sm"
      size={20}
      toggled={isMobileMenuOpen}
      onToggle={(e: any) => {
        toggleBurger();
      }}
    />
  );
};

export default HamburgerButton;
