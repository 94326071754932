import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

import { signUpEmail } from '../../services/auth.service';
import { Typography } from '@mui/material';
import { authSelector } from '../../features/auth.slice';

export default function SignUpForm() {
  let navigate = useNavigate();

  const { peetId, currentUrl } = useSelector(authSelector);

  const [isLoading, setisLoading] = useState<boolean>(false);
  const [formData, setformData] = useState<any>({
    email: '',
    password: ''
  });
  const [formErrors, setformErrors] = useState<any>();
  const [resDetails, setresDetails] = useState<any>({});

  const handleInputOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputName = event.target.name;
    const inputValue = event.target.value;

    setformData({ ...formData, [inputName]: inputValue });

    if (inputValue === '') {
      setformErrors({ ...formErrors, [inputName]: true });
      return;
    }
    setformErrors({ ...formErrors, [inputName]: false });
    setresDetails({});
  };

  const handleSignUp = async () => {
    try {
      setisLoading(true);

      let isFormReady = true;

      let stateFormErrors = formErrors;

      for (const prop in formData) {
        if (formData[prop] === '') {
          isFormReady = false;
          stateFormErrors = { ...stateFormErrors, [prop]: true };
        }
      }
      setformErrors(stateFormErrors);

      if (!isFormReady) return;

      const signUpPayload = formData;
      if (peetId) signUpPayload.characterId = peetId;
      if (currentUrl) signUpPayload.currentUrl = currentUrl;

      const serviceRes: any = await signUpEmail(formData);

      if (serviceRes.isSucceeded) {
        if (!peetId) {
          navigate('/complete-signup');
        }
        if (peetId) {
          setresDetails({
            isReqFailed: false,
            message:
              'Email submitted successfully. Please check your email for verification.'
          });
        }
      } else {
        setresDetails({ isReqFailed: true, message: serviceRes.message });
      }
    } catch (error) {
      console.error('[Error] in sign up form submit', error);
    } finally {
      setisLoading(false);
    }
  };

  return (
    <form onSubmit={(e) => e.preventDefault()}>
      {resDetails.isReqFailed === true && (
        <Typography align="center" color={'red'}>
          {resDetails.message}
        </Typography>
      )}
      {resDetails.isReqFailed === false && (
        <Typography align="center">{resDetails.message}</Typography>
      )}
      <Grid
        id="verify-grid-container"
        container
        style={{
          margin: '0px',
          padding: '8px 12px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
          alignItems: 'flex-end'
        }}
      >
        <Grid item xs={12}>
          <TextField
            fullWidth
            variant="standard"
            label="Email"
            id="new-email"
            name="email"
            placeholder="Enter Your Email"
            error={formErrors?.email}
            helperText={formErrors?.email ? 'Enter email' : ''}
            onChange={handleInputOnChange}
            style={{
              padding: '0px',
              margin: '8px 4px 0px 0px',
              flexGrow: '1',
              borderRadius: 0
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            variant="standard"
            label="Password"
            id="new-password"
            name="password"
            type={'password'}
            error={formErrors?.password}
            helperText={formErrors?.password ? 'Enter password' : ''}
            onChange={handleInputOnChange}
            style={{
              padding: '0px',
              margin: '8px 4px 0px 0px',
              flexGrow: '1',
              borderRadius: 0
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={5}>
          <Button
            fullWidth
            variant="contained"
            type="submit"
            onClick={handleSignUp}
            disabled={isLoading}
            style={{
              margin: '24px 0px 0px 0px',
              fontSize: '16px'
            }}
          >
            Sign Up
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}
