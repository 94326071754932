import React from 'react';
import ReactDOM from 'react-dom';
import _ from 'lodash';
import TagManager from 'react-gtm-module';
import { Provider } from 'react-redux';

import './index.css';
import App from './App';
import { GOOGLE_TAG_MANAGER_ID } from './constants';
import store from './redux/store';
import { getUser } from './features/auth.slice';

const token = localStorage.getItem('token') || '';
const tagManagerArgs = {
  gtmId: GOOGLE_TAG_MANAGER_ID
};

TagManager.initialize(tagManagerArgs);

if (!_.isEmpty(token)) {
  store.dispatch(getUser());
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
