import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';

import { authSelector } from '../../features/auth.slice';
import SignUpForm from './SignUpForm';

export default function SignUp() {
  let navigate = useNavigate();

  const { isAuth } = useSelector(authSelector);

  useEffect(() => {
    let navPath = '/';

    if (isAuth) {
      navPath = '/profile';
      navigate(navPath);
    }
  }, [isAuth, navigate]);

  return (
    <Grid
      container
      component={Paper}
      alignItems={'center'}
      justifyContent={'center'}
      // style={{ height: '100vh' }}
    >
      <Paper
        variant="outlined"
        style={{
          margin: '20px',
          padding: '0px',
          minWidth: '300px',
          display: 'flex',
          flexDirection: 'column',
          flexWrap: 'nowrap',
          alignContent: 'center',
          justifyContent: 'center',
          alignItems: 'stretch'
        }}
      >
        <h1 style={{ textAlign: 'center' }}>Sign Up </h1>

        <Grid
          id="signup-grid-container"
          container
          style={{
            margin: '0px',
            padding: '12px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'stretch'
          }}
        >
          <SignUpForm />
          <Grid
            id="verify-grid-container"
            container
            style={{
              margin: '0px',
              padding: '8px 12px',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              alignItems: 'flex-end'
            }}
          >
            <Grid item xs={12} sm={12} md={5}>
              <Button
                fullWidth
                variant="contained"
                onClick={() => {
                  navigate('/login');
                }}
                style={{
                  margin: '8px 0px',
                  fontSize: '16px'
                }}
              >
                Login
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
}
