import React from 'react';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

import {
  CharityWrapper
  // charityStyles
} from './Charites.style';

export default function Charities() {
  return (
    <CharityWrapper
      id="charity-wrapper"
      // sx={{ backgroundSize: { xs: 'auto', md: 'contain' } }}
    >
      <Grid
        id="grid-container"
        container
        style={{
          margin: '0px',
          // padding: '12px',
          display: 'flex',
          flexFlow: 'row nowrap',
          justifyContent: 'flex-start',
          alignItems: 'center',
          flexWrap: 'nowrap',
          flexDirection: 'column'
        }}
      >
        <Typography
          component="h1"
          variant="h2"
          color="white"
          gutterBottom
          style={{ fontSize: '3em' }}
        >
          Our Partner Charities
        </Typography>
        <p style={{ color: '#FFF' }}>
          A big thank you to all our charity partners for believing in Chatrity.{' '}
          <a
            href="https://hello.chatrity.com/charities"
            target={'_blank'}
            rel="noreferrer"
            style={{ color: '#FFF' }}
          >
            Click here
          </a>{' '}
          to learn more on them.
        </p>
      </Grid>

      <Grid container spacing={'16px'}>
        <Grid item sm={12} md={6} lg={3}>
          <Card>
            <CardContent>
              <img
                src="https://hello.chatrity.com/img/charity_logos/gds.webp"
                style={{ width: '100%' }}
                alt=""
              />
            </CardContent>
          </Card>
        </Grid>
        <Grid item sm={12} md={6} lg={3}>
          <Card>
            <CardContent>
              <img
                src="https://hello.chatrity.com/img/charity_logos/sosd.webp"
                style={{ width: '100%' }}
                alt=""
              />
            </CardContent>
          </Card>
        </Grid>
        <Grid item sm={12} md={6} lg={3}>
          <Card>
            <CardContent>
              <img
                src="https://hello.chatrity.com/img/charity_logos/suss.webp"
                style={{ width: '100%' }}
                alt=""
              />
            </CardContent>
          </Card>
        </Grid>
        <Grid item sm={12} md={6} lg={3}>
          <Card>
            <CardContent>
              <img
                src="https://hello.chatrity.com/img/charity_logos/redcross.webp"
                style={{ width: '100%' }}
                alt=""
              />
            </CardContent>
          </Card>
        </Grid>
        <Grid item sm={12} md={6} lg={3}>
          <Card>
            <CardContent>
              <img
                src="https://hello.chatrity.com/img/charity_logos/yyd.webp"
                style={{ width: '100%' }}
                alt=""
              />
            </CardContent>
          </Card>
        </Grid>
        <Grid item sm={12} md={6} lg={3}>
          <Card>
            <CardContent>
              <img
                src="https://hello.chatrity.com/img/charity_logos/nls.webp"
                style={{ width: '100%' }}
                alt=""
              />
            </CardContent>
          </Card>
        </Grid>
        <Grid item sm={12} md={6} lg={3}>
          <Card>
            <CardContent>
              <img
                src="https://hello.chatrity.com/img/charity_logos/trybe.webp"
                style={{ width: '100%' }}
                alt=""
              />
            </CardContent>
          </Card>
        </Grid>
        <Grid item sm={12} md={6} lg={3}>
          <Card>
            <CardContent>
              <img
                src="https://hello.chatrity.com/img/charity_logos/aas.webp"
                style={{ width: '100%' }}
                alt=""
              />
            </CardContent>
          </Card>
        </Grid>
        <Grid item sm={12} md={6} lg={3}>
          <Card>
            <CardContent>
              <img
                src="https://hello.chatrity.com/img/charity_logos/ww.webp"
                style={{ width: '100%' }}
                alt=""
              />
            </CardContent>
          </Card>
        </Grid>
        <Grid item sm={12} md={6} lg={3}>
          <Card>
            <CardContent>
              <img
                src="https://hello.chatrity.com/img/charity_logos/tat.webp"
                style={{ width: '100%' }}
                alt=""
              />
            </CardContent>
          </Card>
        </Grid>
        <Grid item sm={12} md={6} lg={3}>
          <Card>
            <CardContent>
              <img
                src="https://hello.chatrity.com/img/charity_logos/dsa.webp"
                style={{ width: '100%' }}
                alt=""
              />
            </CardContent>
          </Card>
        </Grid>
        <Grid item sm={12} md={6} lg={3}>
          <Card>
            <CardContent>
              <img
                src="https://hello.chatrity.com/img/charity_logos/bcs.webp"
                style={{ width: '100%' }}
                alt=""
              />
            </CardContent>
          </Card>
        </Grid>
        <Grid item sm={12} md={6} lg={3}>
          <Card>
            <CardContent>
              <img
                src="https://hello.chatrity.com/img/charity_logos/psalt.webp"
                style={{ width: '100%' }}
                alt=""
              />
            </CardContent>
          </Card>
        </Grid>
        <Grid item sm={12} md={6} lg={3}>
          <Card>
            <CardContent>
              <img
                src="https://hello.chatrity.com/img/charity_logos/fffa.webp"
                style={{ width: '100%' }}
                alt=""
              />
            </CardContent>
          </Card>
        </Grid>
        <Grid item sm={12} md={6} lg={3}>
          <Card>
            <CardContent>
              <img
                src="https://hello.chatrity.com/img/charity_logos/nos.webp"
                style={{ width: '100%' }}
                alt=""
              />
            </CardContent>
          </Card>
        </Grid>
        <Grid item sm={12} md={6} lg={3}>
          <Card>
            <CardContent>
              <img
                src="https://hello.chatrity.com/img/charity_logos/spca.webp"
                style={{ width: '100%' }}
                alt=""
              />
            </CardContent>
          </Card>
        </Grid>
        <Grid item sm={12} md={6} lg={3}>
          <Card>
            <CardContent>
              <img
                src="https://hello.chatrity.com/img/charity_logos/cccs.webp"
                style={{ width: '100%' }}
                alt=""
              />
            </CardContent>
          </Card>
        </Grid>
        <Grid item sm={12} md={6} lg={3}>
          <Card>
            <CardContent>
              <img
                src="https://hello.chatrity.com/img/charity_logos/hca.webp"
                style={{ width: '100%' }}
                alt=""
              />
            </CardContent>
          </Card>
        </Grid>
        <Grid item sm={12} md={6} lg={3}>
          <Card>
            <CardContent>
              <img
                src="https://hello.chatrity.com/img/charity_logos/acres.webp"
                style={{ width: '100%' }}
                alt=""
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </CharityWrapper>
  );
}
