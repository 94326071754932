// import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';

export const FaqWrapper = styled('div')(({ theme }) => ({
  padding: '80px 5%',
  margin: '0px',
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  boxSizing: 'border-box',
  backgroundColor: '#0f0330'
}));
