import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { authSelector } from '../features/auth.slice';

const PrivateRoutes = () => {
  const location = useLocation();

  const { isAuth } = useSelector(authSelector);

  if (isAuth) return <Outlet />;

  return <Navigate to="/login" replace state={{ from: location }} />;
};

export default PrivateRoutes;
