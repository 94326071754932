import { createTheme } from '@mui/material/styles';
// import { red } from '@mui/material/colors';

const theme = createTheme({
  breakpoints: {
    keys: ['xs', 'sm', 'md', 'lg', 'xl'],
    values: {
      xs: 0,
      sm: 360,
      md: 768,
      lg: 1024,
      xl: 1216
    }
  },
  spacing: 4,
  typography: {
    button: {
      textTransform: 'none'
    },
    fontFamily: [
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(',')
  }
  // palette: {
  //   primary: {
  //     main: '#a70ad4',
  //     light: '#dd53ff',
  //     dark: '#7200a2'
  //   },
  //   secondary: {
  //     main: '#fff'
  //   }
  // }
});

export default theme;
