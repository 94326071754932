import React from 'react';
import { Helmet } from 'react-helmet';

interface CustomMetaProps {
  title: string;
  imageUrl?: string;
  description: string;
}

export default function CustomMeta(props: CustomMetaProps) {
  const { title, imageUrl, description } = props;
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />

      <meta name="twitter:title" content={title} />
      {imageUrl !== '' && <meta name="twitter:image" content={imageUrl} />}
      <meta name="twitter:description" content={description} />

      <meta property="og:title" content={title} />
      {imageUrl !== '' && <meta property="og:image" content={imageUrl} />}
      <meta property="og:description" content={description} />
    </Helmet>
  );
}
