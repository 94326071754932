import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import { authSelector } from '../../features/auth.slice';
import EmailLogin from './EmailLogin';
import PhoneLogin from './PhoneLogin';

export default function Login() {
  let navigate = useNavigate();

  const { isAuth } = useSelector(authSelector);

  useEffect(() => {
    let navPath = '/';

    if (isAuth) {
      navPath = '/profile';
      navigate(navPath);
    }
  }, [isAuth, navigate]);

  return (
    <Grid
      id="grid-container"
      container
      component={Paper}
      style={{
        margin: '0px',
        padding: '80px 12px',
        display: 'flex',
        flexFlow: 'row nowrap',
        justifyContent: 'flex-start',
        alignItems: 'center',
        flexWrap: 'nowrap',
        flexDirection: 'column',
        backgroundColor: '#0f0330'
      }}
    >
      <Typography
        component="h1"
        variant="h5"
        color="inherit"
        gutterBottom
        style={{ margin: '16px 0px 24px 0px' }}
      >
        Login to your Profile
      </Typography>

      <Grid
        item
        component={Paper}
        variant="outlined"
        xs={12}
        sm={12}
        md={7}
        lg={6}
        xl={5}
        style={{ width: '100%', marginBottom: '24px' }}
      >
        <PhoneLogin />
      </Grid>
      <Grid
        item
        component={Paper}
        variant="outlined"
        xs={12}
        sm={12}
        md={7}
        lg={6}
        xl={5}
        style={{ width: '100%' }}
      >
        <Typography
          variant="h6"
          color="inherit"
          paragraph
          style={{ padding: '8px 12px', margin: '0px' }}
        >
          With Email
        </Typography>
        <EmailLogin />
        <Grid item container justifyContent={'flex-end'}>
          <Grid item xs={12} sm={12} md={5}>
            <Button
              variant="text"
              fullWidth
              onClick={() => navigate('/signup')}
              style={{
                margin: '12px 0px 12px 0px',
                fontSize: '16px'
              }}
            >
              Register
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
