import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import TelegramIcon from '@mui/icons-material/Telegram';
import ArticleIcon from '@mui/icons-material/Article';
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';
import { headerStyles } from './Header.style';
import { authSelector } from '../../features/auth.slice';

interface IHeaderMenusProps {
  setisMobileMenuOpen?: Function;
}

const HeaderMenus = (props: IHeaderMenusProps) => {
  const { isAuth } = useSelector(authSelector);
  const classes = headerStyles();
  const { setisMobileMenuOpen } = props;

  const handleMenuClick = (event: any) => {
    // event.preventDefault();
    if (isAuth) return;
    if (!setisMobileMenuOpen) return;
    if (setisMobileMenuOpen) {
      setisMobileMenuOpen(false);
    }
  };

  return (
    <>
      <Grid item className={classes.gridMenuItem}>
        <RouterLink
          to={'/'}
          style={{ color: '#fff', textDecoration: 'none' }}
          onClick={handleMenuClick}
        >
          <VolunteerActivismIcon style={{ fontSize: '16px', margin: '0px 5px' }} />
          Campaigns
        </RouterLink>
      </Grid>
      <Grid item className={classes.gridMenuItem}>
        <Link
          underline="none"
          color="#fff"
          href="https://docs.google.com/document/d/13c-XGzZSBYQf9e_qOkCc9VR2OTjBaKQtwAKgu-l2Vzs/edit?usp=sharing"
          target="_blank"
          onClick={handleMenuClick}
        >
          <ArticleIcon style={{ fontSize: '16px', margin: '0px 5px' }} />
          Whitepaper
        </Link>
      </Grid>
      <Grid item className={classes.gridMenuItem}>
        <Link
          underline="none"
          color="#fff"
          href="https://t.me/ChatrityBot"
          target="_blank"
          onClick={handleMenuClick}
        >
          <TelegramIcon style={{ fontSize: '16px', margin: '0px 5px' }} />
          Telegram
        </Link>
      </Grid>
      <Grid item className={classes.gridMenuItem}>
        {isAuth === true && (
          <RouterLink
            to={'/profile'}
            style={{
              color: '#fff',
              textDecoration: 'none'
            }}
            onClick={handleMenuClick}
          >
            <button className={'menu-button'}>
              <span className={'menu-button-text'}>Profile</span>
            </button>
          </RouterLink>
        )}
        {isAuth === false && (
          <RouterLink
            to={'/login'}
            style={{
              color: '#fff',
              textDecoration: 'none'
            }}
            onClick={handleMenuClick}
          >
            <button className={'menu-button'}>
              <span className={'menu-button-text'}>Login</span>
            </button>
          </RouterLink>
        )}
      </Grid>
    </>
  );
};

export default HeaderMenus;
