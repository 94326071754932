import axios from 'axios';
import _ from 'lodash';

import { PEET_NFT_SERVER } from '../constants';
import { IResponse } from '../interfaces';
import { ECampaignTypes } from '../enum';

// export const getCharacter = async (endpoint: string, data: any): Promise<any> => {
//   const token = localStorage.getItem('token') || '';
//   const response = await axios.post(
//     `${process.env.REACT_APP_MAIN_API_DOMAIN}${endpoint}`,
//     { dashboard_user: data },
//     {
//       headers: {
//         Authorization: `Bearer ${token}`
//       }
//     }
//   );
// };

export const getCharacter = async (
  // cancelToken: any,
  id: any
): Promise<any> => {
  const resData: IResponse = {
    isSucceeded: false,
    message: '',
    error: {},
    item: {}
  };

  try {
    const response = await axios.get(`${PEET_NFT_SERVER}/api/peet/${id}`, {
      headers: {
        'Content-Type': 'application/json'
      }
      // cancelToken: cancelToken
    });
    const itemData = response.data;

    if (_.isEmpty(itemData)) {
      resData.message = 'No Peet Found';
      return resData;
    }

    resData.isSucceeded = true;

    const isAdopted = itemData.adopted && !_.isEmpty(itemData.adopted) ? true : false;
    const peetType =
      itemData.masterChar && itemData.masterChar.name
        ? itemData.masterChar.name.replace(/[_-]/g, ' ')
        : '';
    const peetImgBgColor =
      itemData.parts && itemData.parts.length && itemData.parts[1].color
        ? itemData.parts[1].color
        : '';
    const campaignCategory =
      itemData.masterChar && itemData.masterChar.category
        ? itemData.masterChar.category.toLowerCase()
        : '';
    let footerText = '';
    let donateDescription = '';
    let donateButtonText = 'Donate Now';
    let metaTitle = '';
    let metaDescription = '';
    switch (campaignCategory) {
      case ECampaignTypes.Peace:
        donateDescription =
          'Available for redemption. 100% proceeds will go to Singapore Red Cross.';
        footerText = `100% of proceeds will go to Red Cross Singapore in support of the Ukraine humanitarian crisis response.`;
        metaTitle = 'Stand for love and peace. Donate SGD 10 to Singapore Red Cross';
        metaDescription =
          'Help rebuild the lives of those who are suffering in the conflict and receive our exclusive Peace NFT!';
        break;
      case ECampaignTypes.Sosd10:
        donateDescription = `Available for adoption. Proceeds will go to charities supporting the
        Animal Welfare causes.`;
        footerText = `100% of proceeds will go to Chatrity fundraising platform's partner animal charities - SPCA, SOSD and ACRES to fund emergency rescues, rehabilitation, medical expenses and more.`;
        metaTitle = `Let's show love to our strays.`;
        metaDescription = `Celebrate SOSD's 10th Year Anniversary by improving the lives of more than 500 stray dogs and receive our special Stars of SOSD NFT!`;
        break;
      case ECampaignTypes.TrybeForYouth:
        donateDescription = 'Available for redemption. 100% proceeds will go to Trybe.';
        footerText =
          "100% of proceeds will go to Trybe in support of the youth programmes and management of the Singapore Boys' Hostel (SBHL).";
        metaTitle = `Let's play our part in nurturing our future generation`;
        metaDescription = `"Trybe for Youth" NFT series encapsulates the spirit of Resilience (Steady), Promise (Onz) and Vibrance for life (Swee La) that has helped Trybe's youth overcome their adversities and pursue their dreams.`;
        break;
      case ECampaignTypes.TrybeYouthRising:
        donateDescription = 'Available for redemption. 100% proceeds will go to Trybe.';
        footerText =
          "100% of proceeds will go to Trybe in support of the youth programmes and management of the Singapore Boys' Hostel (SBHL).";
        metaTitle = `Let's play our part in nurturing our future generation`;
        metaDescription = `"Trybe for Youth" NFT series encapsulates the spirit of Resilience (Steady), Promise (Onz) and Vibrance for life (Swee La) that has helped Trybe's youth overcome their adversities and pursue their dreams.`;
        break;
      case ECampaignTypes.HundredStrays:
        donateDescription = `Available for adoption. Proceeds will go to charities supporting the
        Animal Welfare causes.`;
        footerText = `100% of proceeds will go to Chatrity fundraising platform's partner animal charities - SPCA, SOSD and ACRES to fund emergency rescues, rehabilitation, medical expenses and more.`;
        donateButtonText = 'Donate to Adopt';
        metaTitle = 'Adopt a digital stray with a SGD 30 donation';
        metaDescription = `Donate to animal welfare and Collect an Exclusive NFT!
`;
        break;

      default:
        break;
    }

    const minAmount =
      itemData.masterChar && itemData.masterChar.minAmount
        ? itemData.masterChar.minAmount
        : 30;

    resData.item = {
      isPeetAdopted: isAdopted,
      peetId: itemData.id,
      peetName: itemData.name,
      peetType: peetType,
      peetAbout: itemData.description,
      peetImgBgColor: peetImgBgColor,
      owners: itemData.adopted,
      isPeetAvailable: itemData.isAvailable,
      campaignCategory: campaignCategory,
      minAmount: minAmount,
      footerText,
      donateDescription,
      donateButtonText,
      metaTitle,
      metaDescription
    };

    return resData;
  } catch (error) {
    if (axios.isCancel(error)) {
      console.log('[Info]: Character API aborted successfully!');
    } else {
      console.error('[Error]: getCharacter', error);
    }
    resData.error = error;
    return resData;
  }
};

export const adoptCharacter = async (
  // cancelToken: any,
  payload: any
): Promise<any> => {
  const resData: IResponse = {
    isSucceeded: false,
    message: '',
    error: {},
    item: {}
  };

  try {
    const token = localStorage.getItem('token') || '';
    const refCode = localStorage.getItem('refCode') || '';
    if (refCode !== '') payload.refCode = localStorage.getItem('refCode');

    const response = await axios.post(`${PEET_NFT_SERVER}/api/adopt`, payload, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
      // cancelToken: cancelToken
    });

    resData.isSucceeded = true;
    resData.item = response.data;

    localStorage.removeItem('refCode');
    return resData;
  } catch (error) {
    if (axios.isCancel(error)) {
      console.log('[Info]: adoptCharacter API aborted successfully!');
    } else {
      console.error('[Error]: adoptCharacter', error);
    }
    resData.error = error;
    return resData;
  }
};
