import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import MuiPhoneNumber from 'material-ui-phone-number';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';

import { generateOTP, resendOTP, login } from '../../services';
import { setAuthSuccess } from '../../features/auth.slice';

export default function PhoneLogin() {
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const [isSendOtpLoading, setisSendOtpLoading] = useState<boolean>(false);
  const [isResendOtpLoading, setisResendOtpLoading] = useState<boolean>(false);
  const [isVerifyLoading, setisVerifyLoading] = useState<boolean>(false);
  const [loginData, setloginData] = useState<any>();
  const [isVerifyFormVisible, setisVerifyFormVisible] = useState<boolean>(false);
  const [errors, seterrors] = useState<any>({});
  const [formResult, setformResult] = useState<any>({});

  const handlePhoneOnChange = (value: string) => {
    if (value && value.length > 3) {
      setloginData({ ...loginData, phoneNumber: value });
      seterrors({ ...errors, phoneNumber: false });
    } else {
      seterrors({ ...errors, phoneNumber: true });
    }
    setformResult({});
  };

  const handleInputOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputName = event.target.name;
    const inputValue = event.target.value;
    if (inputValue && inputValue.length === 6) {
      setloginData({ ...loginData, [inputName]: inputValue });
      seterrors({ ...errors, [inputName]: false });
    } else {
      seterrors({ ...errors, [inputName]: true });
    }
    setformResult({});
  };

  const handleResendOTP = async () => {
    setisResendOtpLoading(true);
    const payload = {
      REQUEST_ID: loginData.requestId,
      phoneNumber: loginData.phoneNumber
    };
    const otpRes: any = await resendOTP(payload);

    if (otpRes.isSucceeded) {
      setloginData({ ...loginData, requestId: otpRes.item.requestId });
      setisVerifyFormVisible(true);
    }
    setisResendOtpLoading(false);
  };

  const handleSendOTP = async () => {
    try {
      setisSendOtpLoading(true);
      if (!loginData || !loginData.phoneNumber || loginData.phoneNumber < 3) {
        seterrors({ ...errors, phoneNumber: true });
        return;
      }
      const payload = { phoneNumber: loginData.phoneNumber };
      const serviceRes: any = await generateOTP(payload);

      if (serviceRes.isSucceeded) {
        setloginData({ ...loginData, requestId: serviceRes.item.requestId });
        setisVerifyFormVisible(true);
      } else {
        setformResult({ isFormFailed: true, message: serviceRes.message });
      }
    } catch (error) {
      console.error('[Error] in OTP form submit', error);
    } finally {
      setisSendOtpLoading(false);
    }
  };

  const handleVerifyAndLogin = async () => {
    setisVerifyLoading(true);
    if (!loginData || !loginData.otp || loginData.otp < 6) {
      seterrors({ ...errors, otp: true });
      return;
    }

    const payload = {
      phoneNumber: loginData.phoneNumber,
      REQUEST_ID: loginData.requestId,
      CODE: loginData.otp
    };

    const serviceRes: any = await login(payload);
    setisVerifyLoading(false);
    if (serviceRes.isSucceeded) {
      dispatch(setAuthSuccess(serviceRes.item));
      navigate('/profile');
    }
  };

  return (
    <>
      <Typography variant="h6" color="inherit" paragraph style={{ padding: '8px 12px' }}>
        With Phone Number
      </Typography>
      <form onSubmit={(e) => e.preventDefault()}>
        {formResult.message !== '' && (
          <Typography
            paragraph
            align="center"
            style={{ padding: '8px 12px', color: 'red' }}
          >
            {formResult.message}
          </Typography>
        )}
        <Grid
          id="verify-grid-container"
          container
          style={{
            margin: '0px',
            padding: '8px 12px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            alignItems: 'flex-end'
          }}
        >
          <Grid item xs={12} sm={12}>
            <MuiPhoneNumber
              id="phone-number"
              name="phoneNumber"
              className="mui-phone-number"
              variant="standard"
              fullWidth
              defaultCountry={'sg'}
              enableLongNumbers={true}
              countryCodeEditable={true}
              error={errors?.phoneNumber}
              helperText={errors?.phoneNumber ? 'Enter phone number' : ''}
              onChange={(e: any) => {
                handlePhoneOnChange(e);
              }}
              style={{
                padding: '0px',
                margin: '8px 4px 0px 0px',
                flexGrow: '1',
                borderRadius: 0
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={5}>
            <Button
              fullWidth
              variant="contained"
              type="submit"
              onClick={handleSendOTP}
              disabled={isSendOtpLoading}
              style={{
                margin: '24px 0px 0px 0px',
                fontSize: '16px'
              }}
            >
              Send OTP
            </Button>
          </Grid>
        </Grid>
      </form>

      {isVerifyFormVisible === true && (
        <Paper style={{ margin: '12px' }} variant="outlined">
          <Grid
            container
            flexDirection={'column'}
            justifyContent={'flex-start'}
            alignItems={'flex-start'}
            style={{ padding: '12px 12px 0px 12px' }}
          >
            <Typography>
              Verify Your Phone Number
              <br />
              A text message with a verification code was just sent to you.
              <br />
              The code will be valid for 3 minutes.
            </Typography>
            <Button
              variant="text"
              onClick={handleResendOTP}
              disabled={isResendOtpLoading}
            >
              Send OTP Again
            </Button>
          </Grid>
          <form onSubmit={(e) => e.preventDefault()}>
            <Grid
              id="verify-grid-container"
              container
              style={{
                margin: '0px',
                padding: '12px',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
                alignItems: 'flex-end'
              }}
            >
              <Grid item xs={12} sm={12}>
                <TextField
                  variant="standard"
                  label="OTP"
                  id="otp"
                  name="otp"
                  fullWidth
                  placeholder="Enter OTP"
                  // type={'number'}
                  error={errors?.otp}
                  helperText={errors?.otp ? 'Enter 6 Digits OTP' : ''}
                  onChange={handleInputOnChange}
                  style={{
                    padding: '0px',
                    margin: '8px 4px 0px 0px',
                    flexGrow: '1',
                    borderRadius: 0
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={5}>
                <Button
                  fullWidth
                  variant="contained"
                  type="submit"
                  onClick={handleVerifyAndLogin}
                  disabled={isVerifyLoading}
                  style={{
                    margin: '24px 0px 0px 0px',
                    fontSize: '16px'
                  }}
                >
                  Verify and Login
                </Button>
              </Grid>
            </Grid>
          </form>
        </Paper>
      )}
    </>
  );
}
