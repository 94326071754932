import React from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';

export default function PageNotFound() {
  let location = useLocation();

  return (
    <Grid
      container
      flexDirection={'column'}
      alignContent={'center'}
      alignItems={'center'}
      justifyContent={'center'}
      style={{ height: '100vh' }}
    >
      <Paper
        style={{
          minWidth: '600px',
          minHeight: '200px',
          textAlign: 'center',
          display: 'flex',
          flexDirection: 'column',
          flexWrap: 'nowrap',
          alignContent: 'center',
          justifyContent: 'center',
          alignItems: 'stretch'
        }}
      >
        <h1>Error 404 </h1>
        <p>
          Page/Content not found for{' '}
          <span style={{ color: '#9c27b0' }}>{location.pathname}</span>
        </p>
        <p>
          <RouterLink to="/" style={{ textDecoration: 'none' }}>
            <Button variant="contained">Back to Home</Button>
          </RouterLink>
        </p>
      </Paper>
    </Grid>
  );
}
