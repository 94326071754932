import { combineReducers } from '@reduxjs/toolkit';
import authSliceReducer from '../features/auth.slice';
import dialogSliceReducer from '../features/dialogBox.slice';

const rootReducer = combineReducers({
  auth: authSliceReducer,
  dialog: dialogSliceReducer
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
