import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AppThunk } from '../redux/store';
import { RootState } from '../redux/rootReducer';
import {
  // login as serviceLogin,
  getUser as serviceGetUser
} from '../services';

export interface AuthError {
  message: string;
}

export interface AuthState {
  isAuth: boolean;
  currentUser?: CurrentUser;
  currentUrl?: string;
  peetId?: number;
  isLoading: boolean;
  error: AuthError;
}

export interface CurrentUser {
  id: number;
  display_name: string;
  email: string;
  photo_url: string;
}

export const initialState: AuthState = {
  isAuth: false,
  isLoading: false,
  error: { message: 'An Error occurred' }
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setCurrentUrl: (state: AuthState, { payload }: PayloadAction<string>) => {
      state.currentUrl = payload;
    },
    setPeetId: (state: AuthState, { payload }: PayloadAction<number>) => {
      state.peetId = payload;
    },
    setLoading: (state: AuthState, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload;
    },
    setAuthSuccess: (state, { payload }: PayloadAction<CurrentUser>) => {
      state.currentUser = payload;
      state.isAuth = true;
    },
    setLogOut: (state) => {
      state.isAuth = false;
      state.currentUser = undefined;
      localStorage.removeItem('token');
    },
    setAuthFailed: (state, { payload }: PayloadAction<AuthError>) => {
      state.error = payload;
      state.isAuth = false;
    }
  }
});

export const getUser = (): AppThunk => async (dispatch) => {
  try {
    // dispatch(setLoading(true));
    const serviceRes = await serviceGetUser();
    if (serviceRes.isSucceeded) {
      dispatch(setAuthSuccess(serviceRes.item));
    }
  } catch (error: any) {
    // dispatch(setAuthFailed(error));
  } finally {
    // dispatch(setLoading(false));
  }
};

// export const login = (): AppThunk => async (dispatch) => {
//   try {
//     dispatch(setLoading(true));
//     const currentUser = serviceLogin('https://auth-end-point.com/login');
//     dispatch(setAuthSuccess(currentUser));
//   } catch (error) {
//     dispatch(setAuthFailed(error));
//   } finally {
//     dispatch(setLoading(false));
//   }
// };

// export const logOut = (): AppThunk => async (dispatch) => {
//   try {
//     dispatch(setLoading(true));
//     await endUserSession('https://auth-end-point.com/log-out');
//   } catch (error) {
//     dispatch(setAuthFailed(error));
//   } finally {
//     dispatch(setLoading(false));
//   }
// };

export default authSlice.reducer;

export const {
  setAuthSuccess,
  setLogOut,
  setLoading,
  setAuthFailed,
  setPeetId,
  setCurrentUrl
} = authSlice.actions;

export const authSelector = (state: RootState) => state.auth;
