import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

// import { EDialogTypes } from '../../enum';
import EmailLogin from '../../pages/login/EmailLogin';
import SignUpForm from '../../pages/signUp/SignUpForm';
import {
  dialogSelector,
  setCloseDialog,
  setCloseLoginDialog
} from '../../features/dialogBox.slice';

export default function CustomDialogBox() {
  const dispatch = useDispatch();

  const { isOpen, isLoginDialogOpen, dialogContent } = useSelector(dialogSelector);

  switch (dialogContent.type) {
    case 'LOGIN':
      return (
        <Dialog
          fullWidth={true}
          maxWidth={'sm'}
          open={isLoginDialogOpen}
          onClose={() => dispatch(setCloseLoginDialog())}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{dialogContent.title}</DialogTitle>
          <DialogContent>
            <Grid
              item
              component={Paper}
              variant="outlined"
              xs={12}
              style={{ width: '100%', marginBottom: '24px' }}
            >
              <Typography
                variant="h6"
                color="inherit"
                paragraph
                style={{ padding: '8px 12px', margin: '0px' }}
              >
                Log In
              </Typography>
              <EmailLogin />
            </Grid>
            <Grid
              item
              component={Paper}
              variant="outlined"
              xs={12}
              style={{ width: '100%', marginBottom: '24px' }}
            >
              <Typography
                variant="h6"
                color="inherit"
                paragraph
                style={{ padding: '8px 12px', margin: '0px' }}
              >
                Sign Up
              </Typography>
              <SignUpForm />
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => dispatch(setCloseLoginDialog())}>Close</Button>
          </DialogActions>
        </Dialog>
      );
    case 'NOTIFICATION':
    default:
      return (
        <Dialog
          fullWidth={true}
          maxWidth={'sm'}
          open={isOpen}
          onClose={() => dispatch(setCloseDialog())}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{dialogContent.title}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {dialogContent.message}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => dispatch(setCloseDialog())}>Close</Button>
          </DialogActions>
        </Dialog>
      );
  }
}
