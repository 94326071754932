import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import logo from '../../images/chatrity-logo.svg';

import { FooterContainer, footerStyles } from './Footer.style';

export default function Footer() {
  const classes = footerStyles();

  return (
    <Grid
      id="footer-wrapper"
      container
      justifyContent={'center'}
      className={classes.wrapper}
    >
      <FooterContainer id="footer-container">
        <Grid
          container
          style={{
            minHeight: '84px',
            display: 'flex',
            flexDirection: 'column',
            flexWrap: 'nowrap',
            alignContent: 'center',
            justifyContent: 'center',
            alignItems: 'stretch'
          }}
          spacing={'4px'}
          justifyContent={'flex-start'}
        >
          <RouterLink
            to="/"
            style={{
              textDecoration: 'none'
            }}
          >
            <p style={{ textAlign: 'center' }}>
              <img src={logo} alt="" />
            </p>
            <p className={'footer-brand-name'}>PEET</p>
            <p className={'footer-brand-subtitle'}>
              Personality Embedded Evolving Tokens
            </p>
          </RouterLink>
        </Grid>
        <Grid
          container
          direction={'row'}
          justifyContent={'space-evenly'}
          style={{ padding: '40px 0px' }}
        >
          <Grid item md={3} style={{ textAlign: 'center', padding: '12px' }}>
            <a
              href="https://opensea.io/chatrity"
              target={'_blank'}
              rel="noreferrer"
              className={'footer-link'}
            >
              OpenSea Listing
            </a>
          </Grid>
          <Grid item md={3} style={{ textAlign: 'center', padding: '12px' }}>
            <RouterLink to="/charities" className={'footer-link'}>
              Partner Charities
            </RouterLink>
          </Grid>
          <Grid item md={3} style={{ textAlign: 'center', padding: '12px' }}>
            <RouterLink to="/faq" className={'footer-link'}>
              Frequently Asked Questions
            </RouterLink>
          </Grid>
          <Grid item md={3} style={{ textAlign: 'center', padding: '12px' }}>
            <a
              href="https://hello.chatrity.com/about-us"
              target={'_blank'}
              rel="noreferrer"
              className={'footer-link'}
            >
              About Chatrity
            </a>
          </Grid>
        </Grid>

        <Grid container>
          <p className="footer-copy-right">
            © 2022 | CHATRITY PTE LTD (UEN: 202103903E) | All Rights Reserved
          </p>
        </Grid>
      </FooterContainer>
    </Grid>
  );
}
