import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../redux/rootReducer';

export interface IDialogError {
  message: string;
}

export interface IDialog {
  title: string;
  message: string;
  type: string;
}

export interface IDialogState {
  isOpen: boolean;
  isLoginDialogOpen: boolean;
  dialogContent: IDialog;
  error: IDialogError;
}

export const initialState: IDialogState = {
  isOpen: false,
  isLoginDialogOpen: false,
  dialogContent: {
    title: '',
    message: '',
    type: ''
  },
  error: { message: 'Something went wrong.' }
};

export const dialogSlice = createSlice({
  name: 'dialog',
  initialState,
  reducers: {
    setOpenLoginDialog: (state: IDialogState, { payload }: PayloadAction<IDialog>) => {
      state.isLoginDialogOpen = true;
      state.dialogContent = payload;
    },
    setCloseLoginDialog: (state: IDialogState) => {
      state.isLoginDialogOpen = false;
    },
    setOpenDialog: (state: IDialogState, { payload }: PayloadAction<IDialog>) => {
      state.isOpen = true;
      state.dialogContent = payload;
    },
    setCloseDialog: (state) => {
      state.isOpen = false;
      state.dialogContent = {
        title: '',
        message: '',
        type: ''
      };
    }
  }
});

export default dialogSlice.reducer;

export const { setOpenDialog, setCloseDialog, setOpenLoginDialog, setCloseLoginDialog } =
  dialogSlice.actions;

export const dialogSelector = (state: RootState) => state.dialog;
