import React from 'react';
import Grid from '@mui/material/Grid';

import SideMenu from './SideMenu';
import ContactInfo from './ContactInfo';
import DonationHistory from './DonationHistory';
import MyPets from './MyPets';

export default function Profile() {
  return (
    <Grid container style={{ padding: '80px 5%' }}>
      <SideMenu />
      <Grid item xs={12} sm={12} md={9} lg={9}>
        <ContactInfo />
        <DonationHistory />
        <MyPets />
      </Grid>
    </Grid>
  );
}
