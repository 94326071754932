import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import { headerStyles, HeaderContainer } from './Header.style';
import HeaderMenus from './HeaderMenus';
import peetLogo from '../../images/peet.svg';
import logo from '../../images/logo.svg';
import HamburgerButton from './HamburgerButton';

const StaticHeader = (props: any) => {
  const classes = headerStyles();
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down('lg'));

  const [isMobileMenuOpen, setisMobileMenuOpen] = useState<boolean>(false);

  return (
    <Grid
      id="static-header-grid-container"
      container
      style={{
        backgroundColor: isMobileMenuOpen ? '#320338' : '',
        position: 'absolute',
        top: '0px',
        left: '0px',
        zIndex: '1',
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'nowrap',
        alignContent: 'center',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <div
        id="static-header-wrapper"
        className={classes.staticHeaderWrapper}
        style={{
          backgroundColor: isMobileMenuOpen ? '#320338' : ''
        }}
      >
        <HeaderContainer id="static-header-container">
          <Grid item flexGrow="1">
            <RouterLink
              to="/"
              style={{
                width: '140px',
                textDecoration: 'none',
                color: '#fff',
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'nowrap',
                alignItems: 'center',
                justifyContent: 'flex-start'
              }}
            >
              <img src={peetLogo} className="App-logo" alt="" />
              <img src={logo} className="logo-text" alt="" />
            </RouterLink>
          </Grid>
          {isMobileView === false && <HeaderMenus />}
          {isMobileView === true && (
            <Grid item style={{ marginRight: '-14px' }}>
              {' '}
              <HamburgerButton
                isMobileMenuOpen={isMobileMenuOpen}
                setisMobileMenuOpen={setisMobileMenuOpen}
              />
            </Grid>
          )}
        </HeaderContainer>
      </div>

      {isMobileView === true && isMobileMenuOpen === true && (
        <Grid
          container
          direction={'column'}
          flexWrap={'nowrap'}
          justifyContent={'center'}
          alignItems={'stretch'}
          style={{
            zIndex: '1',
            top: '64px',
            left: '0px',
            backgroundColor: '#320338',
            margin: '0px',
            padding: '0px 0px 8px 0px'
          }}
        >
          <HeaderMenus setisMobileMenuOpen={setisMobileMenuOpen} />
        </Grid>
      )}
    </Grid>
  );
};
export default StaticHeader;
