import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';

import { HomeWrapper } from './Home.style';

import heroBannerHome from '../../images/hero-banner-home.png';
import heroBannerMain from '../../images/hero-banner-main.png';
import heroBannerPeace from '../../images/hero-banner-peace.png';
import trybeHeroImg from '../../images/trybe-hero-img.png';
import trybeRisingHeroImg from '../../images/trybe-rising-hero.png';
import sosdBanner from '../../images/home-img-02.png';

import chatrityLogo from '../../images/chatrity-logo.png';

import logo from '../../images/chatrity-logo.svg';
import opensea from '../../images/opensea.svg';
import metamask from '../../images/metamask.svg';
import polygon from '../../images/polygon.svg';

export default function Home() {
  return (
    <HomeWrapper>
      <Grid
        id="grid-container"
        container
        style={{
          margin: '0px',
          padding: '12px',
          display: 'flex',
          flexFlow: 'row nowrap',
          justifyContent: 'flex-start',
          alignItems: 'center',
          flexWrap: 'nowrap',
          flexDirection: 'column'
        }}
      >
        <Grid container>
          <Grid item md={12} lg={6}>
            <Box
              sx={{
                position: 'relative',
                p: { xs: 3, md: 6 },
                pr: { md: 0 },
                color: '#fff',
                mb: 10
              }}
            >
              <Typography
                component="h1"
                variant="h2"
                color="inherit"
                gutterBottom
                style={{ fontSize: '3em' }}
              >
                Bridging Donations and Non Fungible Tokens with ease and fun!
              </Typography>
              <Typography variant="h6" color="inherit" paragraph>
                Donate to the campaigns below and receive our limited and exclusive NFTs!
                100% of proceeds to go our
                <RouterLink
                  to="/charities"
                  style={{
                    margin: '0px',
                    textDecoration: 'none'
                  }}
                >
                  <Button
                    size="medium"
                    variant="contained"
                    color="secondary"
                    style={{
                      margin: '0px 0px 0px 8px'
                    }}
                  >
                    Partner Charities
                  </Button>
                </RouterLink>
              </Typography>
            </Box>
          </Grid>
          <Grid item md={12} lg={6}>
            <LazyLoadImage
              alt={''}
              src={heroBannerHome}
              style={{
                width: '100%'
              }}
            />
          </Grid>
        </Grid>

        <Grid container spacing={'10px'} style={{ margin: '28px 0px 48px 0px' }}>
          <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
            <Card
              style={{
                backgroundColor: '#320338',
                padding: '10px',
                margin: '0px auto',
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                flexWrap: 'nowrap',
                justifyContent: 'flex-start',
                alignItems: 'stretch'
              }}
            >
              <CardContent style={{ flexGrow: 1 }}>
                <LazyLoadImage
                  alt={''}
                  src={heroBannerMain}
                  style={{
                    width: '100%'
                  }}
                />
                <Typography
                  variant="h2"
                  color="white"
                  style={{ fontSize: '1.7em', padding: '5px' }}
                >
                  Donate to animal welfare and Collect an Exclusive NFT!
                </Typography>
              </CardContent>
              <CardActions
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  flexDirection: 'column'
                }}
              >
                <Stack direction="row" spacing={1} style={{ width: '100%' }}>
                  <Chip
                    label="This campaign has ended."
                    variant="outlined"
                    style={{ width: '100%', color: '#FFF' }}
                  />
                </Stack>
                <Button
                  href="/100-peets"
                  size="medium"
                  variant="contained"
                  color="primary"
                  style={{ marginTop: '20px', width: '100%', fontSize: '20px' }}
                >
                  100 Strays Campaign
                </Button>
              </CardActions>
            </Card>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
            <Card
              style={{
                backgroundColor: '#111',
                padding: '10px',
                margin: '0px auto',
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                flexWrap: 'nowrap',
                justifyContent: 'flex-start',
                alignItems: 'stretch'
              }}
            >
              <CardContent style={{ flexGrow: 1 }}>
                <LazyLoadImage
                  alt={''}
                  src={sosdBanner}
                  style={{
                    width: '100%'
                  }}
                />
                <Typography
                  variant="h2"
                  color="white"
                  style={{ fontSize: '1.2em', padding: '5px' }}
                >
                  Celebrate SOSD's 10th Year Anniversary by donating to improve the lives
                  of more than 500 dogs and receive an exclusive NFT!
                </Typography>
              </CardContent>
              <CardActions
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  flexDirection: 'column'
                }}
              >
                <Stack direction="row" spacing={1} style={{ width: '100%' }}>
                  <Chip
                    label="This campaign has ended."
                    variant="outlined"
                    style={{ width: '100%', color: '#FFF' }}
                  />
                </Stack>
                <Button
                  href="/sosdbig10"
                  size="medium"
                  variant="contained"
                  color="primary"
                  style={{ marginTop: '20px', width: '100%', fontSize: '20px' }}
                >
                  SoSD
                </Button>
              </CardActions>
            </Card>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
            <Card
              style={{
                backgroundColor: '#111',
                padding: '10px',
                margin: '0px auto',
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                flexWrap: 'nowrap',
                justifyContent: 'flex-start',
                alignItems: 'stretch'
              }}
            >
              <CardContent style={{ flexGrow: 1 }}>
                <LazyLoadImage
                  alt={''}
                  src={trybeRisingHeroImg}
                  style={{
                    width: '100%'
                  }}
                />
                <Typography
                  variant="h2"
                  color="white"
                  style={{ fontSize: '1.2em', padding: '5px' }}
                >
                  Help realise the full potential of Trybe's youth by donating to support
                  Trybe's programmes and receive an exclusive NFT!
                </Typography>
              </CardContent>
              <CardActions
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  flexDirection: 'column'
                }}
              >
                <Stack direction="row" spacing={1} style={{ width: '100%' }}>
                  <Chip
                    label="This campaign has ended."
                    variant="outlined"
                    style={{ width: '100%', color: '#FFF' }}
                  />
                </Stack>
                <Button
                  href="/trybeyouthrising"
                  size="medium"
                  variant="contained"
                  color="primary"
                  style={{ marginTop: '20px', width: '100%', fontSize: '20px' }}
                >
                  Youth Rising Month
                </Button>
              </CardActions>
            </Card>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
            <Card
              style={{
                backgroundColor: '#111',
                padding: '10px',
                margin: '0px auto',
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                flexWrap: 'nowrap',
                justifyContent: 'flex-start',
                alignItems: 'stretch'
              }}
            >
              <CardContent style={{ flexGrow: 1 }}>
                <Grid container>
                  <LazyLoadImage
                    alt={''}
                    src={trybeHeroImg}
                    style={{
                      width: '100%'
                    }}
                  />
                  <Typography
                    variant="h2"
                    color="white"
                    style={{ fontSize: '1.5em', padding: '5px', marginTop: '20px' }}
                  >
                    Help realise the full potential of Trybe's youth by donating to
                    support Trybe's programmes and receive an exclusive NFT!
                  </Typography>
                </Grid>
              </CardContent>
              <CardActions
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  flexDirection: 'column'
                }}
              >
                <Stack direction="row" spacing={1} style={{ width: '100%' }}>
                  <Chip
                    label="This campaign has ended."
                    variant="outlined"
                    style={{ width: '100%', color: '#FFF' }}
                  />
                </Stack>
                <Button
                  href="/trybe4youth"
                  size="medium"
                  variant="contained"
                  color="primary"
                  style={{ marginTop: '20px', width: '100%', fontSize: '20px' }}
                >
                  Trybe
                </Button>
              </CardActions>
            </Card>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
            <Card
              style={{
                backgroundColor: '#173582',
                padding: '10px',
                margin: '0px auto',
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                flexWrap: 'nowrap',
                justifyContent: 'flex-start',
                alignItems: 'stretch'
              }}
            >
              <CardContent style={{ flexGrow: 1 }}>
                <Grid container>
                  <LazyLoadImage
                    alt={''}
                    src={heroBannerPeace}
                    style={{
                      width: '100%'
                    }}
                  />
                  <Typography
                    variant="h2"
                    color="white"
                    style={{ fontSize: '1.5em', padding: '5px' }}
                  >
                    Help rebuild the lives of those who are suffering in the conflict and
                    receive our exclusive Peace NFT!
                  </Typography>
                </Grid>
              </CardContent>
              <CardActions
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  flexDirection: 'column'
                }}
              >
                <Stack direction="row" spacing={1} style={{ width: '100%' }}>
                  <Chip
                    label="This campaign has ended."
                    variant="outlined"
                    style={{ width: '100%', color: '#FFF' }}
                  />
                </Stack>
                <Button
                  href="/peace"
                  size="medium"
                  variant="contained"
                  color="primary"
                  style={{ marginTop: '20px', width: '100%', fontSize: '16px' }}
                >
                  Ukraine Fundraising
                </Button>
              </CardActions>
            </Card>
          </Grid>
        </Grid>

        <Grid container spacing={'10px'}>
          <Grid
            item
            xs={6}
            md={6}
            lg={3}
            style={{ textAlign: 'center', padding: '12px' }}
          >
            <img
              className={'hero-logo'}
              src={logo}
              style={{ opacity: '0.5', width: '100%', maxWidth: '160px' }}
              alt=""
            />
          </Grid>
          <Grid
            item
            xs={6}
            md={6}
            lg={3}
            style={{ textAlign: 'center', padding: '12px' }}
          >
            <img
              className={'hero-logo'}
              src={opensea}
              style={{ opacity: '0.5', width: '100%', maxWidth: '160px' }}
              alt=""
            />
          </Grid>
          <Grid
            item
            xs={6}
            md={6}
            lg={3}
            style={{ textAlign: 'center', padding: '12px' }}
          >
            <img
              className={'hero-logo'}
              src={metamask}
              style={{ opacity: '0.5', width: '100%', maxWidth: '160px' }}
              alt=""
            />
          </Grid>
          <Grid
            item
            xs={6}
            md={6}
            lg={3}
            style={{ textAlign: 'center', padding: '12px' }}
          >
            <img
              className={'hero-logo'}
              src={polygon}
              style={{ opacity: '0.5', width: '100%', maxWidth: '160px' }}
              alt=""
            />
          </Grid>
        </Grid>

        <Grid item xs={12} textAlign="center">
          <Typography
            component="h1"
            variant="h2"
            color="white"
            gutterBottom
            style={{ fontSize: '2em' }}
          >
            About Chatrity
          </Typography>
        </Grid>

        <Grid
          container
          paddingTop="30px"
          justifyContent={'center'}
          // style={{
          //   backgroundColor: '#33082E'
          // }}
        >
          <Grid item md={12} lg={8} textAlign="center" marginBottom="40px">
            <Card
              style={{
                backgroundColor: '#0f0330'
              }}
            >
              <Grid container padding="20px">
                <Grid
                  item
                  container
                  justifyContent={'center'}
                  alignItems={'center'}
                  md={12}
                  lg={4}
                >
                  <img src={chatrityLogo} alt="Chatrity Logo" />
                </Grid>
                <Grid item md={12} lg={8} textAlign="left" color="white">
                  <Typography
                    variant="h6"
                    style={{
                      textAlign: 'left',
                      padding: '0px',
                      margin: '18px 12px 16px 12px',
                      fontWeight: 'bold'
                    }}
                  >
                    Giving is just a chat away
                  </Typography>
                  <Typography
                    style={{
                      textAlign: 'left',
                      padding: '0px',
                      margin: '8px 12px 8px 12px'
                    }}
                  >
                    Chatrity began as the Corporate Social Responsibility project of Call
                    Levels. We are applying our proven and secure AI banking chatbot to
                    facilitate micro-donations on social messaging platforms with a
                    variety of payment methods. Donors would be able to make cause-based
                    donations by giving to multiple charities under the same cause (e.g.
                    Environment, Elderly, Animal Welfare) with just a chat.
                  </Typography>
                  <Button
                    href="https://hello.chatrity.com/about-us"
                    target={'_blank'}
                    rel="noreferrer"
                    size="medium"
                    variant="contained"
                    color="secondary"
                    style={{
                      margin: '8px 12px 18px 12px'
                    }}
                  >
                    Click to know more
                  </Button>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </HomeWrapper>
  );
}
