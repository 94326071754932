import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';

import { setLogOut } from '../../features/auth.slice';

export default function ContactInfo() {
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const handleLogoutClick = () => {
    dispatch(setLogOut());
    navigate('/');
  };

  return (
    <Grid item xs={12} sm={12} md={3} lg={3} style={{ marginBottom: '20px' }}>
      <Card sx={{ marginRight: '20px' }}>
        <nav aria-label="secondary mailbox folders">
          <List>
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemText primary="Profile" />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton component="a" href="#donation-history">
                <ListItemText primary="Donation History" />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton component="a" href="#my-peets">
                <ListItemText primary="My PEETs" />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton component="a" onClick={handleLogoutClick}>
                <ListItemText primary="Log out" />
              </ListItemButton>
            </ListItem>
          </List>
        </nav>
      </Card>
    </Grid>
  );
}
